import { FunctionComponent, useState } from 'react';
import Card from '../../components/Card/Card';
import CardRow from '../../components/CardRow/CardRow';
import CreditSummaryCard from '../../components/CreditSummaryCard/CreditSummaryCard';
import { User, CreditCard } from 'react-feather';
import Banner from '../../components/Banner/Banner';
import AuthService from '../../services/auth-service';
import Button from '../../components/Button/Button';
import { UserInfo } from '../../models/user-model';

const Account: FunctionComponent = (): JSX.Element => {
  const [resetPasswordClcked, setResetPasswordClicked] = useState(false);
  const currentUser = AuthService.getUser();
  const userBusinessProfile = AuthService.getUserBusinessInfo();
  const userBillingProfile = AuthService.getUserBillingInfo();

  const handleResetPassword = async () => {
    const currentUserEmail = currentUser?.email;

    if (currentUserEmail) {
      await AuthService.emailForgotPassword(currentUserEmail);
      setResetPasswordClicked(true);
    }
  }

  const renderAccountDetails = (profile: UserInfo, fullName: string) => (
    <>
      <div className='mt-3'><strong>Full Name</strong></div>
      <div>{fullName}</div>
      <div className='mt-3'><strong>Email</strong></div>
      <div>{currentUser?.email}</div>
      <div className='mt-3'><strong>Phone</strong></div>
      <div>{profile?.phoneNumber}</div>
      <div className='mt-3'><strong>Address</strong></div>
      {
        profile?.addressLine1 &&
        <div>
          {`${profile?.addressLine1 || ''}
        ${profile?.addressLine2 && (', ' + profile?.addressLine2)}, 
        ${profile?.city}, 
        ${profile?.state}, 
        ${profile?.country}, 
        ${profile?.zip}`
          }
        </div>
      }
    </>
  )

  return (
    <div>
      <CardRow
        cards={[
          <Card
            title='Business Information'
            actionFunc={() => AuthService.openModal('businessInfo')}
            actionLabel='Edit Info'
          >
            {!AuthService.isBusinessProfileComplete() &&
              <Banner type='error' text='Complete your business information' />}
            <User size={50} />
            {userBusinessProfile && renderAccountDetails(userBusinessProfile, AuthService.getBusinessProfileFullName())}
          </Card>,
          <Card
            title='Billing Information'
            actionFunc={() => AuthService.openModal('billingInfo')}
            actionLabel='Edit Info'
          >
            {!AuthService.isBillingProfileComplete() &&
              <Banner type='error' text='Complete your billing information' />}
            <CreditCard size={50} />
            {userBillingProfile && renderAccountDetails(userBillingProfile, AuthService.getBillingProfileFullName())}
          </Card>,
        ]}
      />
      <CardRow
        cards={[
          <CreditSummaryCard available={currentUser?.balance || 0} pending={0}>Test Content</CreditSummaryCard>,
          <Card title='Change Password'>
            <div className='mb-1'>Get a password reset link sent to your business email address.</div>
            {
              resetPasswordClcked ?
                <Banner type='success' text='Reset password email has been sent' />
                : <Button type='primary' onClick={handleResetPassword}>Change</Button>
            }
          </Card>,
        ]}
      />
    </div >
  );
};

export default Account;
