import { FormikErrors, FormikValues, FormikTouched, FormikHelpers } from 'formik';
import { FunctionComponent, ReactNode } from 'react';
import { FieldType, FormGroupFieldMap } from '../../lib/Form/fieldsArray';
import { RadioGroupOption } from '../../lib/Form/RadioGroupOption';
import FormField from '../Form/FormField/FormField';
import Pill from '../Pill/Pill';
import { RateEstimateType } from './MultiStepForm';
import cx from 'classnames';
import './MultiStepFormContent';
import { getDecimal, getCarrierLabel, getCarrierLogo } from '../../services/helper';

export type MultiStepFormContentProps = {
  fieldsArray: Array<FieldType>;
  step: number;
  errors?: FormikErrors<FormikValues>;
  touched?: FormikTouched<FormikValues>;
  values?: FormikValues;
  additionalValues?: null | RateEstimateType[];
  handleOnPasteFunc?: (event: ClipboardEvent, setFieldValue: FormikHelpers<FormikValues>) => Promise<void>;
  setFieldValue?: FormikHelpers<FormikValues>;
  component: FunctionComponent<MultiStepFormComponentProps>;
  formGroupFieldMap?: FormGroupFieldMap;
};

export type MultiStepFormComponentProps = {
  errors?: FormikErrors<FormikValues>;
  touched?: FormikTouched<FormikValues>;
  values?: FormikValues | undefined;
  children: ReactNode;
  formGroupFieldMap?: FormGroupFieldMap;
}

const MultiStepFormContent: FunctionComponent<MultiStepFormContentProps> = ({
  step,
  component,
  fieldsArray,
  ...contentProps
}): JSX.Element => {
  const Component = component;
  const currentFieldsArray = fieldsArray.filter(f => f.step === step);
  const formGroupFieldMap: FormGroupFieldMap = {};

  currentFieldsArray.forEach(f => {
    let field = f;

    if (contentProps?.additionalValues && field.name === 'rateId') {
      field = {
        ...f,
        options: contentProps.additionalValues.map((v: RateEstimateType): RadioGroupOption => {
          const carrierLabel = getCarrierLabel(v.carrierName);
          const isFlatRate = v?.packageType?.indexOf('flat') > -1;

          return {
            label: carrierLabel[1] + (isFlatRate ? ' Flat Rate' : ''),
            type: carrierLabel[0],
            date: v.estimatedDeliveryDate,
            amount: v.totalAmount,
            subLabel: `Est. ${new Date(v.estimatedDeliveryDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}`,
            svgLabel: getCarrierLogo(v.carrierName),
            value: v.rateId,
            pillComponent: <Pill subLabel='CAD' label={'$' + getDecimal(v.totalAmount)} type={'tertiary'} />,
          }
        }),
      }
      if (contentProps?.values && contentProps?.additionalValues && !contentProps?.values['rateId']) {
        contentProps.values['rateId'] = contentProps?.additionalValues[0]?.rateId || '';
      }
    }
    if (formGroupFieldMap[field.formGroup]) {
      formGroupFieldMap[field.formGroup].push(field);
    } else {
      formGroupFieldMap[field.formGroup] = [field];
    }
  })

  const renderFields = <>
    {
      Object.keys(formGroupFieldMap).map((key, index): ReactNode => {
        return (
          <div
            className={cx('multi-step-form__group', {
              'multi-step-form__group__radio': formGroupFieldMap[Number(key)].findIndex(f => f.fieldType === 'radio') > -1,
            })}
            key={`multi-step-form-group-${index}`}
          >
            {
              formGroupFieldMap[Number(key)].map((field, i): ReactNode => {
                return (
                  <FormField key={`multi-step-form-content-${i}`} {...contentProps} {...field} />
                )
              })
            }
          </div>
        )
      })
    }
  </>
  const renderFormFields = !contentProps?.values && renderFields;

  return (
    <Component
      {...(contentProps?.values && {
        values: contentProps.values,
        formGroupFieldMap,
        ...contentProps,
      })}
    >
      {renderFormFields}
    </Component>
  );
};

export default MultiStepFormContent;
