import { batchColumnType, RowData } from '../lib/table/summaryCardCols';
import ApiService from './api-service';
import AuthService from './auth-service';
import DBService from './db-service';
import { generateCSV } from './helper';

class DocumentServiceClass {
  private static instance: DocumentServiceClass;

  public static getInstance(): DocumentServiceClass {
    if (!DocumentServiceClass.instance) {
      DocumentServiceClass.instance = new DocumentServiceClass();
    }
    return DocumentServiceClass.instance;
  }

  async createDocuments(selectedData: any): Promise<boolean | any> {
    try {
      if (selectedData.selectedRows.length === 0) {
        throw Error('Cannot Create Documents without Batch Id');
      }

      const batchData = selectedData.selectedRows.filter((d: batchColumnType) => d.batchStatus);
      const batchShipmentsMap: { [batchId: string]: RowData[] } = {};
      const batchLabelIdsMap: { [batchId: string]: string[] } = {};

      batchData.forEach((d: batchColumnType) => {
        const shipments = selectedData.selectedRows
          .filter((selectedRow: RowData) => selectedRow.parentId && selectedRow.parentId === d.batchId);

        if (shipments) {
          batchShipmentsMap[d.batchId] = shipments;
          batchLabelIdsMap[d.batchId] = shipments.map((s: RowData) => s.shipEngine?.labelId);
        }
      });

      const { data } = await ApiService.post('createManifest', {
        batchLabelIdsMap,
        userId: AuthService.getUserId(),
      });

      if (data) {
        if (data?.err?.details) {
          return data;
        }

        // Temporarily Disabled
        // const { manifestLinks = [] } = data;
        // downloadLinks(manifestLinks);

        Object.values(batchShipmentsMap).forEach((shipments: RowData[]) => {
          generateCSV(shipments);
        })


        const batchIds = Object.keys(batchShipmentsMap);

        // Generate documents
        for (let i = 0; i < batchIds.length; i++) {
          const batchId = batchIds[i];
          const shipments = batchLabelIdsMap[batchId];

          await DBService.updateShipmentStatus(shipments, batchId, 'INPROGRESS');
        }

        return true;
      }
    } catch (error) {
      return error;
    }
  }
}

const DocumentService = DocumentServiceClass.getInstance();

export default DocumentService;
