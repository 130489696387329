import AuthService from '../../services/auth-service';
import BigStat from '../BigStat/BigStat';
import Card from '../Card/Card';
import Loader from '../Loader/Loader';

type Stat = {
  label: string,
  value: number,
}

const SummaryCard = (): JSX.Element => {
  const stats: Stat[] = [
    {
      label: 'Total Shipments',
      value: AuthService.getUser()?.totalShipments || 0,
    },
    {
      label: 'Pending',
      value: AuthService.getUser()?.totalPendingShipments || 0,
    },
    {
      label: 'Batched',
      value: AuthService.getUser()?.totalInProgressShipments || 0,
    },
    {
      label: 'Cancelled',
      value: AuthService.getUser()?.totalCancelledShipments || 0,
    },
    {
      label: 'Completed',
      value: AuthService.getUser()?.totalCompletedShipments || 0,
    },
  ];

  return (
    <Card title='Summary' flex>
      {!stats && (
        <div className='mt-4 mb-4'>
          <Loader />
        </div>
      )}
      {stats && stats?.map(({ label, value }, index) => (
        <BigStat key={`summary-stat-${index}`} label={label} value={value} width={Math.floor(100 / stats.length)} />
      ))}
    </Card>
  );
};

export default SummaryCard;
