import { ReactNode, FunctionComponent } from 'react';
import { PlusCircle } from 'react-feather';
import Tabs from '../Tabs/Tabs';
import cx from 'classnames';
import './Card.scss';

type CardProps = {
  children: ReactNode;
  title?: string;
  actionIcon?: boolean;
  flex?: boolean;
  actionFunc?: React.MouseEventHandler<HTMLButtonElement>;
  actionLabel?: string;
  tabsLabel?: string[];
  tabsContent?: JSX.Element[];
};

const Card: FunctionComponent<CardProps> = ({
  children,
  title,
  actionIcon,
  flex,
  actionFunc,
  actionLabel,
  tabsLabel = [],
  tabsContent,
}): JSX.Element => {
  const renderTitle = title && (
    <div className='card__title'>
      {title}
      {actionLabel && (
      <button className='card__btn' onClick={actionFunc}>
        {actionLabel}
        {actionIcon && <PlusCircle />}
        </button>
      )}
    </div>
  )
  const renderTabs =
    tabsLabel.length > 0
      ? (<Tabs tabsLabel={tabsLabel || []} tabsContent={tabsContent || []} />)
      : children;

  return (
    <div className={cx('card', { 'card__tab': tabsLabel.length > 0, 'card--flex': flex })}>
      {renderTitle}
      <div className='card__body'>
        {renderTabs}
      </div>
    </div>
  );
};

export default Card;
