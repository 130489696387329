export const statusBtnTypeMap: { [key: string]: 'primary' | 'secondary' | 'alert' | 'success' | 'error' } = {
  'READY': 'alert',
  'BATCHED': 'alert',
  'IT': 'secondary',
  'DE': 'success',
  'ERROR': 'error',
  'CANCELLED': 'error',
  'DELIVERED': 'success',
  'INPROGRESS': 'secondary',
  'READYTOPRINT': 'alert',
};
