import { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import PrivateRoute from '../../components/PrivateRoute/PrivateRoute';
import Navbar from '../../components/Navbar/Navbar';
import Header from '../../components/Header/Header';
import routes, { BrzRouteProps } from '../../lib/routes/routes';
import { UserModel } from '../../models/user-model';
import ModalService, { MODAL_STATE } from '../../services/modal-service';
import CancelLabelModal from '../CancelLabelModel/CancelLabelModal';
import CreateSetModal from '../CreateSetModal/CreateSetModal';
import LabelPrintModal from '../LabelPrintModal/LabelPrintModal';
import PaymentModal from '../PaymentModal/PaymentModal';
import ProfileModal from '../ProfileModal/ProfileModal';
import ProceedWithoutValidationModal from '../ProceedWithoutValidationModal/ProceedWithoutValidationModal';
import '../../pages/App/App.scss';
import AuthService from '../../services/auth-service';

interface RouterProps {
  currentUser: UserModel | undefined;
}

const Router = ({ currentUser }: RouterProps): JSX.Element => {
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const loggedIn = !!currentUser;

  useEffect(() => {
    const modalSubscribe = (isModalOpen: boolean) => setShowModal(isModalOpen);

    ModalService.subscribe(modalSubscribe);

    return () => {
      ModalService.unsubscribe(modalSubscribe);
    };
  }, []);

  const publicRoutes = routes.filter(route => route.isPublic);

  // Set current path as cookie if logged in
  if (loggedIn && !publicRoutes.find(route => route.path === location.pathname)) {
    AuthService.setCurrentPathname(location.pathname);
  }

  return (
    <>
      <Switch location={location}>
        {routes.map(
          (
            { component, title, path, isPublic, parent }: BrzRouteProps,
            index,
          ) => {
            const TargetPage = component;
            const key = `route-${title}-${index}`;
            const crumb = { title, path };
            const breadCrumbs = parent
              ? [{ title: parent.title, path: parent.path }, crumb]
              : [crumb];

            if (isPublic) {
              if (loggedIn) {
                const to = AuthService.getCurrentPathname() || '/';

                return <Redirect to={to} path={path} key={key} exact />;
              }

              return (
                <Route path={path} key={key} exact>
                  <TargetPage />
                </Route>
              );
            }

            return (
              <PrivateRoute path={path} key={key} loggedIn={loggedIn} exact>
                <>
                  <Navbar activeRoute={breadCrumbs[0].title} />
                  <div className='app__body'>
                    <Header breadCrumbs={breadCrumbs} />
                    <main className='app__wrapper'>
                      <div className='app__content'>
                        <TargetPage />
                      </div>
                    </main>
                  </div>
                </>
              </PrivateRoute>
            );
          },
        )}
      </Switch>
      {
        showModal &&
        <>
          {ModalService.getModalState() === MODAL_STATE.CREATE_SET_MODAL && <CreateSetModal />}
          {ModalService.getModalState() === MODAL_STATE.PROFILE_MODAL && <ProfileModal />}
          {ModalService.getModalState() === MODAL_STATE.LABEL_PRINT_MODAL && <LabelPrintModal />}
          {ModalService.getModalState() === MODAL_STATE.PAYMENT_MODAL && <PaymentModal />}
          {ModalService.getModalState() === MODAL_STATE.CANCEL_LABEL_MODAL && <CancelLabelModal />}
          {ModalService.getModalState() === MODAL_STATE.PROCEED_WITHOUT_VALIDATION && <ProceedWithoutValidationModal />}
        </>
      }
    </>
  );
};

export default Router;
