import { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { UserModel } from '../../models/user-model';
import logo from '../../assets/logo-dark.png';
import Router from '../../components/Router/Router';
import ApiKeyService from '../../services/api-key-service';
import AuthService from '../../services/auth-service';
import Loader from '../../components/Loader/Loader';
import cx from 'classnames';
import './App.scss';

const PRELOADER_ANIMATION = 1000;
const RERENDER_DURATION = 1250;

const App = (): JSX.Element => {
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>();
  const [animatePreloader, setAnimatePreloader] = useState(false);
  const [appLoaded, setAppLoaded] = useState(false);
  const [stripeKey, setStripeKey] = useState('');
  let stripePromise = null;

  if (stripeKey) {
    stripePromise = loadStripe(stripeKey);
  }

  useEffect(() => {
    const initLoadTimer = () => setTimeout(() => setAnimatePreloader(true), RERENDER_DURATION);
    const animateTimer = () => setTimeout(() => setAppLoaded(true), PRELOADER_ANIMATION + RERENDER_DURATION);

    const authSubscribe = (user: UserModel | undefined, loading: boolean) => {
      setCurrentUser(user);

      if (!animatePreloader && !loading) {
        initLoadTimer();
        animateTimer();
      }
    };

    const getStripeKey = async () => {
      const key = await ApiKeyService.fetchStripeKey();

      if (key) {
        setStripeKey(key);
      }
    }

    AuthService.subscribe(authSubscribe);
    getStripeKey();

    return () => {
      AuthService.unsubscribe(authSubscribe);
      clearTimeout(initLoadTimer());
      clearTimeout(animateTimer());
    };
  }, []);

  return (
    <Elements stripe={stripePromise}>
      <div className='app'>
        {!appLoaded && (
          <div className={cx('app__loader', animatePreloader && 'app__loader--animating')}>
            <div className='app__loader-wrapper'>
              {animatePreloader ? <img src={logo} alt='Breeze Courier' /> : <Loader />}
            </div>
          </div>
        )}
        <BrowserRouter>
          <Router currentUser={currentUser} />
        </BrowserRouter>
      </div>
    </Elements>
  );
};

export default App;
