import { FormikErrors, FormikValues, FormikTouched } from 'formik';
import { OptionsType } from 'react-select';
import { DropdownOption } from '../../../lib/Form/Dropdown';
import { RadioGroupOption } from '../../../lib/Form/RadioGroupOption';
import Checkbox from '../Checkbox/Checkbox';
import DateField from '../DateField/DateField';
import Dropdown from '../Dropdown/Dropdown';
import Radio from '../Radio/Radio';
import TextField from '../TextField/TextField';

type FormFieldProps = {
  placeholder: string;
  fieldType: 'text' | 'checkbox' | 'date' | 'dropdown' | 'radio';
  name: string;
  label: string;
  errors?: FormikErrors<FormikValues>,
  touched?: FormikTouched<FormikValues>,
  type: 'email' | 'password' | 'text' | 'number';
  options?: Array<RadioGroupOption> | OptionsType<DropdownOption>;
  allowPasting?: boolean;
  values?: FormikValues;
};

const FormField = (props: FormFieldProps): JSX.Element => {
  const { fieldType, type, options = [], ...otherProps } = props;

  switch (fieldType) {
    case 'text':
      return <TextField type={type} {...otherProps} />;
    case 'checkbox':
      return <Checkbox {...otherProps} />;
    case 'dropdown':
      return <Dropdown options={options} {...otherProps} />;
    case 'radio':
      return <Radio options={options as Array<RadioGroupOption>} {...otherProps} />;
    case 'date':
      return <DateField {...otherProps} />;
    default:
      return <></>;
  }
};

export default FormField;
