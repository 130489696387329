import * as Yup from 'yup';
import { AnyObjectSchema } from 'yup';
import { AnyObject } from 'yup/lib/types';
import ApiService from '../../services/api-service';
import { US_ZIP_REGEX, PHONE_REGEXP } from './validationRegex';

export const INVALID_ADDRESS_MSG = 'The address is invalid';

export const validationAddress = async (values: AnyObject): Promise<boolean> => {
  const { addressLine1, addressLine2, city, state, zip, country } = values;

  const body = {
    addressLine1,
    addressLine2,
    city,
    state,
    zip,
    country,
  };

  // TODO - move to helper
  try {
    const { data } = await ApiService.post('validateAddress', body);

    return data === 'verified';
  } catch (error) {
    console.log('Address validation failed', error);
    return true;
  }
};

export const RegistrationSchema = Yup.object().shape({
  recipientName: Yup.string().required('Required'),
  addressLine1: Yup.string().required('Required'),
  addressLine2: Yup.string(),
  city: Yup.string().required('Required').when(['military'], (military, schema): AnyObjectSchema => {
    if (military) {
      return schema.oneOf(['APO', 'FPO', 'DPO']);
    }
    return schema;
  }),
  country: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  zip: Yup.string().required('Required').matches(US_ZIP_REGEX, 'Invalid Zip'),
  phoneNumber: Yup.string().matches(PHONE_REGEXP, 'Invalid Phone Format'),
  military: Yup.bool(),
}).test({
  name: 'addressValid',
  test: async function (values: AnyObject) {
    const fields = ['recipientName', 'addressLine1', 'city', 'state', 'zip', 'country'];
    const readyForValidation =  fields.every(f => values[f] && values[f] !== '');

    if (!readyForValidation) {
      return true;
    }
    const isValid = await validationAddress(values);

    return isValid ? true : this.createError({
      path: 'address',
      message: INVALID_ADDRESS_MSG,
    })
  },
});

export const DescriptionSchema = Yup.object().shape({
  typeOfContents: Yup.string().required('Required'),
  descriptionOfContents: Yup.string().required('Required'),
  retailValue: Yup.number().required('Required').when(['currency'], (currency, schema): AnyObjectSchema => {
    if (currency === 'USD') {
      return schema.max(800, 'Retail Value must be less than or equal to 800');
    } else if (currency === 'CAD') {
      return schema.max(1000, 'Retail Value must be less than or equal to 1000');
    }
    return schema;
  }),
  currency: Yup.string().required('Required'),
  packageType: Yup.string().required('Required'),
  weight: Yup.string().required('Required'),
  weightUnit: Yup.string().required('Required'),
  length: Yup.string().when(['height', 'width'], {
    is: (height: string, width: string) => (!!width || !!height),
    then: Yup.string().required('Required'),
  }),
  height: Yup.string().when(['length', 'width'], {
    is: (length: string, width: string) => (!!width || !!length),
    then: Yup.string().required('Required'),
  }),
  width: Yup.string().when(['length', 'height'], {
    is: (length: string, height: string) => (!!length || !!height),
    then: Yup.string().required('Required'),
  }),
  sizeUnit: Yup.string().required('Required'),
  shipFrom: Yup.string().required('Required'),
  shipDate: Yup.string().required('Required'),
}, [['length', 'height'], ['length', 'width'], ['height', 'width']]);

export const PackageSchema = Yup.object().shape({
  packageType: Yup.string().required('Required'),
  weight: Yup.string().required('Required'),
  weightUnit: Yup.string().required('Required'),
  length: Yup.string().required('Required'),
  width: Yup.string().required('Required'),
  height: Yup.string().required('Required'),
  sizeUnit: Yup.string().required('Required'),
});

export const PostageSchema = Yup.object().shape({
  shipFrom: Yup.string().required('Required'),
  shipDate: Yup.string().required('Required'),
});

export const EstimateLabelSchema = Yup.object().shape({
  rateId: Yup.string().required('Required'),
});

export const shipmentFormValidationSchemaArray: Array<AnyObjectSchema> = [RegistrationSchema, DescriptionSchema, EstimateLabelSchema];