import { DropdownOption } from '../lib/Form/Dropdown';

export const militaryStatesOptions: DropdownOption[] = [
  {
    value: 'AA',
    label: 'Armed Forces Central and South Americas (Armed Forces Americas)',
    type: 'military',
  },
  {
    value: 'AP',
    label: 'Armed Forces Pacific',
    type: 'military',
  },
  {
    value: 'AE',
    label: 'Armed Forces Europe (including Canada, Middle East, and Africa)',
    type: 'military',
  },
];

export const statesOptions: DropdownOption[] = [
  {
    value: 'AL',
    label: 'Alabama',
    type: 'US',
  },
  {
    value: 'AK',
    label: 'Alaska',
    type: 'US',
  },
  {
    value: 'AZ',
    label: 'Arizona',
    type: 'US',
  },
  {
    value: 'AR',
    label: 'Arkansas',
    type: 'US',
  },
  {
    value: 'CA',
    label: 'California',
    type: 'US',
  },
  {
    value: 'CO',
    label: 'Colorado',
    type: 'US',
  },
  {
    value: 'CT',
    label: 'Connecticut',
    type: 'US',
  },
  {
    value: 'DE',
    label: 'Delaware',
    type: 'US',
  },
  {
    value: 'DC',
    label: 'District Of Columbia',
    type: 'US',
  },
  {
    value: 'FL',
    label: 'Florida',
    type: 'US',
  },
  {
    value: 'GA',
    label: 'Georgia',
    type: 'US',
  },
  {
    value: 'GI',
    label: 'Hawaii',
    type: 'US',
  },
  {
    value: 'ID',
    label: 'Idaho',
    type: 'US',
  },
  {
    value: 'IL',
    label: 'Illinois',
    type: 'US',
  },
  {
    value: 'IN',
    label: 'Indiana',
    type: 'US',
  },
  {
    value: 'IA',
    label: 'Iowa',
    type: 'US',
  },
  {
    value: 'KS',
    label: 'Kansas',
    type: 'US',
  },
  {
    value: 'KY',
    label: 'Kentucky',
    type: 'US',
  },
  {
    value: 'LA',
    label: 'Louisiana',
    type: 'US',
  },
  {
    value: 'ME',
    label: 'Maine',
    type: 'US',
  },
  {
    value: 'MD',
    label: 'Maryland',
    type: 'US',
  },
  {
    value: 'MA',
    label: 'Massachusetts',
    type: 'US',
  },
  {
    value: 'MI',
    label: 'Michigan',
    type: 'US',
  },
  {
    value: 'MN',
    label: 'Minnesota',
    type: 'US',
  },
  {
    value: 'MS',
    label: 'Mississippi',
    type: 'US',
  },
  {
    value: 'MO',
    label: 'Missouri',
    type: 'US',
  },
  {
    value: 'MT',
    label: 'Montana',
    type: 'US',
  },
  {
    value: 'NE',
    label: 'Nebraska',
    type: 'US',
  },
  {
    value: 'NV',
    label: 'Nevada',
    type: 'US',
  },
  {
    value: 'NH',
    label: 'New Hampshire',
    type: 'US',
  },
  {
    value: 'NJ',
    label: 'New Jersey',
    type: 'US',
  },
  {
    value: 'NM',
    label: 'New Mexico',
    type: 'US',
  },
  {
    value: 'NY',
    label: 'New York',
    type: 'US',
  },
  {
    value: 'NC',
    label: 'North Carolina',
    type: 'US',
  },
  {
    value: 'ND',
    label: 'North Dakota',
    type: 'US',
  },
  {
    value: 'OH',
    label: 'Ohio',
    type: 'US',
  },
  {
    value: 'OK',
    label: 'Oklahoma',
    type: 'US',
  },
  {
    value: 'OR',
    label: 'Oregon',
    type: 'US',
  },
  {
    value: 'PA',
    label: 'Pennsylvania',
    type: 'US',
  },
  {
    value: 'PR',
    label: 'Puerto Rico',
    type: 'US',
  },
  {
    value: 'RI',
    label: 'Rhode Island',
    type: 'US',
  },
  {
    value: 'SC',
    label: 'South Carolina',
    type: 'US',
  },
  {
    value: 'SD',
    label: 'South Dakota',
    type: 'US',
  },
  {
    value: 'TN',
    label: 'Tennessee',
    type: 'US',
  },
  {
    value: 'TX',
    label: 'Texas',
    type: 'US',
  },
  {
    value: 'UT',
    label: 'Utah',
    type: 'US',
  },
  {
    value: 'VT',
    label: 'Vermont',
    type: 'US',
  },
  {
    value: 'VA',
    label: 'Virginia',
    type: 'US',
  },
  {
    value: 'WA',
    label: 'Washington',
    type: 'US',
  },
  {
    value: 'WV',
    label: 'West Virginia',
    type: 'US',
  },
  {
    value: 'WI',
    label: 'Wisconsin',
    type: 'US',
  },
  {
    value: 'WY',
    label: 'Wyoming',
    type: 'US',
  },
]
