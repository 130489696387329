import { ReactNode, forwardRef } from 'react';
import { RowData } from '../../lib/table/summaryCardCols';
import {
  AddBox, ArrowDownward, Check, ChevronLeft, Clear,
  DeleteOutline, Edit, FilterList, FirstPage, LastPage, Remove, SaveAlt, ViewColumn,
  ChevronRight, Search,
} from '@material-ui/icons';
import { Column, Icons } from 'material-table';

export const BATCHED_PAGE_SIZE = 3;
export const PAGE_SIZE = 50;
export const DEBOUNCE_MS = 500;
export const tableIcons: Icons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export type TableProps = {
  columnDefinition: Column<RowData>[];
  rowData: Array<RowData> | undefined;
  actionBtnText?: string | ReactNode;

  actionFunc?: (props?: any) => void | undefined | Promise<any>;
  shouldDisableButtonFunc?: (props?: any) => boolean;
  groupByFieldFunc?: (row: RowData, rows: RowData[]) => RowData | undefined;
};

export type PaginationTableProps = TableProps & {
  page: number;
  pageSize: number;
  totalCount: number;
  pagination: boolean;
  isLoading: boolean;
  disableSelection?: boolean;
  onSearchChange: (searchText: string) => void;
  fetchDataByPage: (page: number, pageSize: number) => void;
}
