import { FunctionComponent } from 'react';
import { RowData } from '../../lib/table/summaryCardCols';
import MaterialTable, { MTableToolbar } from 'material-table';
import './MaterialTable.scss';
import Button from '../Button/Button';
import { tableIcons, PaginationTableProps } from './tableHelper';

const Table: FunctionComponent<PaginationTableProps> = ({
  columnDefinition,
  rowData = [],
  actionBtnText,
  page,
  pageSize,
  actionFunc,
  groupByFieldFunc,
  shouldDisableButtonFunc,
  fetchDataByPage,
  onSearchChange,
  totalCount,
  pagination,
  isLoading,
  disableSelection,
}): JSX.Element => {
  //this prevents Material Table from hanging on new data change
  const columns = columnDefinition.map((column: any) => {
    return { ...column };
  });

  const isDisabled = (props: any) => {
    return shouldDisableButtonFunc ? shouldDisableButtonFunc(props) : props.selectedRows.length === 0;
  }

  return (
    <div className='material-table'>
      <MaterialTable
        columns={columns}
        data={rowData}
        title=''
        isLoading={isLoading}
        icons={tableIcons}
        options={{
          sorting: true,
          selection: !disableSelection,
          searchFieldAlignment: 'left',
          showTextRowsSelected: false,
          defaultExpanded: true,
          paging: pagination,
          pageSize: pageSize,
          pageSizeOptions: [],
          selectionProps: (rowData: RowData) => ({
            disabled: !!rowData?.parentId,
            color: 'primary',
          }),
        }}
        localization={{ toolbar: { searchPlaceholder: 'Search order numbers' } }}
        onChangePage={(page: number, pageSize: number) => {
          pagination && fetchDataByPage(page, pageSize);
        }}
        onSearchChange={(searchText => {
          onSearchChange(searchText);
        })}
        page={page}
        totalCount={totalCount}
        parentChildData={
          groupByFieldFunc && groupByFieldFunc
        }
        components={{
          Toolbar: props => (
            <div className='material-table__toolbar'>
              <MTableToolbar {...props} />
              {
                (actionFunc && actionBtnText) &&
                <Button disabled={isDisabled(props)} type='primary' onClick={() => actionFunc && actionFunc(props)}>
                  {actionBtnText}
                </Button>
              }
            </div>
          ),
        }}
      />
    </div>
  );
};

export default Table;
