import AuthPage from './AuthPage';
import { Redirect, useHistory } from 'react-router';
import AuthService from '../../services/auth-service';
import Card from '../../components/Card/Card';
import Button from '../../components/Button/Button';
import { Link } from 'react-router-dom';
import { Formik, Form, FormikValues, FormikHelpers } from 'formik';
import TextField from '../../components/Form/TextField/TextField';
import { signUpFormValidationSchema } from '../../lib/Form/signUpFormValidationSchema';
import { useState } from 'react';
import Banner from '../../components/Banner/Banner';

const initialValues = {
  email: '',
  password: '',
  passwordConfirm: '',
};

const SignUp = (): JSX.Element => {
  const [formError, setFormError] = useState('');
  const currentHistory = useHistory();

  const handleSubmit = async (values: FormikValues, actions: FormikHelpers<typeof initialValues>) => {
    actions.setSubmitting(false);
    const result = await AuthService.emailSignup(values.email, values.password);

    if (result) {
      setFormError(result?.message || 'Something went wrong, please try again later.');
    } else {
      currentHistory.push('/settings');
    }
  }

  const renderFormError = formError && <Banner type='error' text={formError}/>

  // TODO: Enable this page to public
  const pageOverride = window.location.search === '?newaccount=true';

  return (
    <AuthPage>
      <Card title='Sign Up'>
      { renderFormError }
      {!pageOverride && <Redirect to={'/login'} />}
      <Formik
          initialValues={initialValues}
          validationSchema={signUpFormValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, errors, touched }) => (
            <Form>
              <TextField
                name='email'
                label='Email'
                placeholder='Enter email'
                type='email'
                errors={errors}
                touched={touched}
              />
              <TextField
                name='password'
                label='Password'
                placeholder='Enter password'
                type='password'
                errors={errors}
                touched={touched}
              />
              <TextField
                name='passwordConfirm'
                label='Password Confirm'
                placeholder='Confirm password'
                type='password'
                errors={errors}
                touched={touched}
              />
              <Button type='submit' disabled={isSubmitting}>
                Sign Up
              </Button>
            </Form>
          )}
        </Formik>
        <div className='mt-2'>
          Already have an account?{' '}
          <Link to='/login' className='text-link'>
            Sign in
          </Link>
        </div>
      </Card>
    </AuthPage>
  );
};

export default SignUp;
