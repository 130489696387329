import { ReactNode, useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router';
import cx from 'classnames';
import './Button.scss';

type ButtonProps = {
  children: ReactNode;
  type: 'primary' | 'secondary' | 'dark' | 'submit' | 'cancel';
  margin?: boolean,
  disabled?: boolean,
  smaller?: boolean,
  to?: string,
  onClick?: () => void | Promise<void>;
};

const Button = (props: ButtonProps): JSX.Element => {
  const history = useHistory();
  const isMounted = useRef(false);
  const [animate, setAnimate] = useState(false);
  const { children, type, to, margin, smaller, onClick, disabled = false } = props;

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <button
      className={cx(
        'button',
        {
          'button--primary': type === 'primary' || type === 'submit',
          'button--secondary': type === 'secondary',
          'button--dark': type === 'dark',
          'button--cancel': type === 'cancel',
          'button--animate': animate,
          'button--margin': margin,
          'button--smaller': smaller,
          'button--disabled': disabled,
        },
      )}
      type={type === 'submit' ? 'submit' : 'button'}
      disabled={disabled}
      onClick={() => {
        if (!animate) {
          setAnimate(true);

          setTimeout(() => {
            if (isMounted.current) {
              setAnimate(false);
            }
          }, 750);
        }

        if (to) {
          history.push(to);
        } else if (onClick) {
          onClick();
        }
      }}
    >
      {children}
    </button>
  );
};

export default Button;
