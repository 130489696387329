import firebase from 'firebase/app';
import AuthService from './services/auth-service';
import DBService from './services/db-service';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import CarrierService from './services/carrier-service';

const firebaseConfig = {
  apiKey: 'AIzaSyAM1m_TGSTDr47_aJtW-oAbdnOFMDep6ko',
  authDomain: 'breeze-courier.firebaseapp.com',
  projectId: 'breeze-courier',
  storageBucket: 'breeze-courier.appspot.com',
  messagingSenderId: '1023273304157',
  appId: '1:1023273304157:web:210f8595e0d9b308068db4',
  measurementId: 'G-STYLJT6CPV',
};

// Enable webpack hot reload
declare global {
  interface Window { webpackHotReload: boolean | undefined; }
}

if (!window.webpackHotReload) {
  firebase.initializeApp(firebaseConfig);
  firebase.auth().onAuthStateChanged(async () => {
    AuthService.setLoading(false);

    if (AuthService.isLoggedIn()) {
      const user = await DBService.getCurrentUser();

      AuthService.setUser(user);
      CarrierService.initCarriers();
    } else {
      AuthService.setUser(undefined);
    }
  });

  // firebase.functions().useEmulator('localhost', 5001);
  window.webpackHotReload = true;
}

export const auth: firebase.auth.Auth = firebase.auth();
export const firestore: firebase.firestore.Firestore = firebase.firestore();
export const functions: firebase.functions.Functions = firebase.functions();