import { AnimationItem} from 'lottie-web';

type Frame = {
    currentTime: number;
}

const freezeFirstFrame = (animation: AnimationItem): void => {
  animation.addEventListener('enterFrame', (frame: Frame): void => {
    if (frame.currentTime < 1 && animation.playCount > 0) {
      animation.pause();
    }
  });
}

export default freezeFirstFrame;