import { ExportToCsv } from 'export-to-csv';
import { RowData } from '../lib/table/summaryCardCols';
import { CountModel } from '../models/count-model';
import { UserModel } from '../models/user-model';
import fedexLogo from '../assets/fedex.svg';
import upsLogo from '../assets/ups.svg';
import uspsLogo from '../assets/usps.svg';
import { LabelDetails } from '../components/MultiStepForm/MultiStepForm';
import { ShipmentStatus } from '../models/shipment-model';

export const SHIPMENT = 'shipment';
export const BATCH = 'batch';
export const FEDEX = 'FedEx';
export const USPS = 'USPS';
export const UPS = 'UPS'

type CSVData = {
  'Consignee Name': string;
  'Consignee Address': string;
  'Consignee City': string;
  'Consignee State': string;
  'Consignee Country': string;
  'Consignee Postal Cd': string;
  'Commodity Description': string;
  'Total Number Of Pieces': string | number;
  'Total Number Of Pieces UOM': string;
  'Total Weight': string | number;
  'Total Weight UOM': string;
  'Value (Amount) USD': number;
}

export const getCarrierLogo = (label: string): string | undefined => {
  let logo;

  if (label.toLowerCase().indexOf(FEDEX.toLowerCase()) > -1) {
    logo = fedexLogo;
  }
  if (label.toLowerCase().indexOf(USPS.toLowerCase()) > -1) {
    logo = uspsLogo;
  }
  if (label.toLowerCase().indexOf(UPS.toLowerCase()) > -1) {
    logo = upsLogo;
  }
  return logo;
}
export const splitByName = (label: string, name: string): string => {
  const labelArr = label.replace(/[^a-zA-Z\d\s:]/g, '').split(name + ' ');

  return labelArr.length > 0 ? labelArr[1] : label;
}
export const getCarrierLabel = (label: string): string[] => {
  let carrierLabel = label;
  let carrier = '';

  if (label.toLowerCase().indexOf(FEDEX.toLowerCase()) > -1) {
    carrierLabel = splitByName(label, FEDEX);
    carrier = FEDEX;
  }
  if (label.toLowerCase().indexOf(USPS.toLowerCase()) > -1) {
    carrierLabel = splitByName(label, USPS);
    carrier = USPS;
  }
  if (label.toLowerCase().indexOf(UPS.toLowerCase()) > -1) {
    carrierLabel = splitByName(label, UPS);
    carrier = UPS;
  }
  return [carrier, carrierLabel];
}
export const getTrackingLink = (shipmentDetails: LabelDetails, carriersTrackingLinks: { [key: string]: string }): string => {
  if (shipmentDetails?.trackingNumber && shipmentDetails?.trackingNumber !== 'N/A') {
    if (shipmentDetails?.carrierId && carriersTrackingLinks && carriersTrackingLinks[shipmentDetails?.carrierId]) {
      return `${carriersTrackingLinks[shipmentDetails?.carrierId]}${shipmentDetails?.trackingNumber}`;
    }
  }
  return '';
}

export const sameDay = (d1: Date, d2: Date): boolean => {
  return d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate();
}

export const getDecimal = (num: number): string => {
  return (Math.round(num * 100) / 100).toFixed(2);
}

export const generateId = (user: UserModel, count: number, type: string, dateISOString: string): string => {
  const userPrefix = `${user.billingInfo.firstName?.substr(0, 1) || ''}${user.billingInfo.lastName?.substr(0, 3) || ''}`;
  const typePrefix = type.toLowerCase() === SHIPMENT ? 's' : 'b';

  const countPrefix = String(count).padStart(4, '0');

  const datestamp = dateISOString.split('T')[0].split('-').reverse().join('');

  return `${userPrefix}-${typePrefix}-${countPrefix}-${datestamp}`.toUpperCase();
}

export const generateCount = (currentCount: CountModel, currentDate: Date): number => {
  let count = 1;

  if (sameDay(new Date(currentCount.updatedAt), currentDate)) {
    count = currentCount.count + 1;
  }

  return count;
}

const getValue = (value: string | number | undefined): number => {
  return Number(value || 0);
}

const convertCadToUsd = (value: number): number => {
  return value * 0.8;
}

export const getCSVData = (data: RowData[]): CSVData[] => {
  return data.map((d: RowData) => {
    return {
      'Consignee Name': d?.shipTo.name,
      'Consignee Address': `${d?.shipTo.address}${d?.shipTo.address2 && (',' + d?.shipTo.address2)}`,
      'Consignee City': d?.shipTo.city,
      'Consignee State': d?.shipTo.state,
      'Consignee Country': d?.shipTo.country,
      'Consignee Postal Cd': d?.shipTo.postal,
      'Commodity Description': d?.details.descriptionOfContents || '',
      'Total Number Of Pieces': 1,
      'Total Number Of Pieces UOM': 'pkg',
      'Total Weight': Math.ceil(Number(d?.details.weight) || 0),
      'Total Weight UOM': d?.details.weightUnit || '',
      'Value (Amount) USD': d?.details.currency === 'CAD' ? convertCadToUsd(getValue(d?.details.retailValue)) : getValue(d?.details.retailValue),
    }
  })
}

export const generateCSV = (data: RowData[]): void => {
  const options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: false,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  };
  const csvData = getCSVData(data);
  const csvExporter = new ExportToCsv({ ...options, filename: `${data[0].batchId} Manifest` });

  csvExporter.generateCsv(csvData);
}

export const downloadLinks = async (links: string[]): Promise<void> => {
  links.forEach((l: string) => {
    window.open(l, '_blank');
  });
}

export const statusToLabel = (status?: ShipmentStatus): string => {
  if (status === 'READY') {
    return 'Awaiting Pickup';
  }

  if (status === 'BATCHED') {
    return 'Awaiting Documents';
  }

  if (status === 'READYTOPRINT') {
    return 'Ready To Print';
  }

  if (status === 'DE' || status === 'DELIVERED') {
    return 'Delivered';
  }

  if (status === 'CANCELLED') {
    return 'Cancelled';
  }

  return 'In Progress';
}
