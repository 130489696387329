import { functions } from '../firebase';

/*
 * Api Singleton that allows for cloud function calls.
 * Avoid using directly in react components. Instead abstract calls away to services.
 * Example usage: ApiService.post('helloWorld') => Promise returning "hello world"
 */
class ApiServiceClass {
  private static instance: ApiServiceClass;
  private static appInit: boolean;

  public static getInstance(): ApiServiceClass {
    if (!ApiServiceClass.instance) {
      ApiServiceClass.instance = new ApiServiceClass();
    }
    return ApiServiceClass.instance;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  post(func: string, data: any): Promise<any> {
    return functions.httpsCallable(func)(data);
  }
}

const ApiService = ApiServiceClass.getInstance();

export default ApiService;
