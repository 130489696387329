import { FormikValues } from 'formik';
import { FunctionComponent } from 'react';

const Summary: FunctionComponent<{
  values: FormikValues;
}> = ({
  values,
}): JSX.Element => {
  return (
    <div className='multi-step-form__summary'>
      <div className='multi-step-form__summary-field'>
        <div className='mt-2'><strong>Ship To</strong></div>
        <div>
          {`${values.recipientName}`}
        </div>
        <div>
          {`${values.addressLine1}`}
        </div>
        {values.addressLine2 && <div>{values.addressLine2}</div>}
        <div>
          {`${values.city}, ${values.state}, ${values.country}, ${values.zip}`}
        </div>
      </div>
      <div className='multi-step-form__summary-field'>
        <div className='mt-2'><strong>Ship From</strong></div>
        <div>
          {`Breeze Courier / ${values.shipFrom}`}
        </div>
        <div>
          1717 Mackenna Ave
        </div>
        <div>
          Niagara Falls, NY, US, 14303
        </div>
      </div>
      <div className='multi-step-form__summary-field'>
        <div className='mt-2'><strong>Description</strong></div>
        <div>
          {`${values.retailValue} ${values.currency}`}
        </div>
        <div>
          {`${values.typeOfContents}`}
        </div>
        <div>
          {`${values.descriptionOfContents}`}
        </div>
      </div>
      <div className='multi-step-form__summary-field'>
        <div className='mt-2'><strong>Packaging</strong></div>
        {values.length && (
          <div>
            {`${values.length} X ${values.width} X ${values.height} ${values.sizeUnit} `}
          </div>
        )}
        <div>
          {`${values.weight} ${values.weightUnit}${values.weight > 1 ? 's' : ''}`}
        </div>
      </div>
    </div>
  )
};

export default Summary;
