/* eslint-disable @typescript-eslint/no-explicit-any */
import { shipmentFormValidationSchemaArray } from './shipmentFormValidationSchemas';
import successConfetti from '../../assets/success-confetti.json';
import deliveryTruck from '../../assets/delivery-truck-animation.json';
import { AnyObjectSchema } from 'yup';
import { DropdownOption } from './Dropdown';
import { RadioGroupOption } from './RadioGroupOption';
import { statesOptions, militaryStatesOptions } from '../../constants/statesOptions';
import { provincesOptions } from '../../constants/provincesOptions';

export interface FieldType {
  name: string;
  label: string;
  type: 'email' | 'password' | 'text' | 'number';
  placeholder: string;
  fieldType: 'text' | 'checkbox' | 'date' | 'dropdown' | 'radio';
  options?: DropdownOption[] | RadioGroupOption[];
  step: number;
  formGroup: number;
  optional?: boolean;
  customValidation?: string;
  allowPasting?: boolean;
}

export type FormGroupFieldMap = {
  [formGroup: number]: Array<FieldType>
}

export const fieldsArray: Array<FieldType> = [
  {
    name: 'recipientName',
    label: 'Recipient Name',
    type: 'text',
    fieldType: 'text',
    placeholder: 'Recipient Name',
    step: 1,
    formGroup: 1,
    allowPasting: true,
  },
  {
    name: 'addressLine1',
    label: 'Address Line 1',
    type: 'text',
    fieldType: 'text',
    placeholder: 'Address line 1',
    step: 1,
    formGroup: 2,
    customValidation: 'address',
  },
  {
    name: 'addressLine2',
    label: 'Apartment, suite, unit, building, floor, etc.',
    type: 'text',
    fieldType: 'text',
    placeholder: 'Address line 2',
    step: 1,
    formGroup: 2,
    customValidation: 'address',
    optional: true,
  },
  {
    name: 'city',
    label: 'City',
    type: 'text',
    fieldType: 'text',
    placeholder: 'City',
    step: 1,
    formGroup: 3,
    customValidation: 'address',
  },
  {
    name: 'country',
    label: 'Country',
    type: 'text',
    fieldType: 'dropdown',
    placeholder: 'Country',
    options: [
      { value: 'US', label: 'United States' },
    ],
    step: 1,
    formGroup: 3,
    customValidation: 'address',
  },
  {
    name: 'state',
    label: 'State',
    type: 'text',
    fieldType: 'dropdown',
    placeholder: 'State',
    options: statesOptions.concat(militaryStatesOptions),
    step: 1,
    formGroup: 4,
    customValidation: 'address',
  },
  {
    name: 'zip',
    label: 'Zip',
    type: 'text',
    fieldType: 'text',
    placeholder: 'Zip',
    step: 1,
    formGroup: 4,
    customValidation: 'address',
  },
  {
    name: 'phoneNumber',
    label: 'Phone Number',
    type: 'text',
    fieldType: 'text',
    placeholder: 'Phone number',
    step: 1,
    formGroup: 4,
    optional: true,
  },
  {
    name: 'military',
    label: 'Military Shipment',
    type: 'text',
    fieldType: 'checkbox',
    placeholder: 'Military Shipment',
    step: 1,
    formGroup: 5,
  },
  {
    name: 'shipFrom',
    label: 'Ship From',
    fieldType: 'text',
    type: 'text',
    placeholder: 'Store Front or Personal Full Name',
    step: 2,
    formGroup: 1,
  },
  // {
  //   name: 'typeOfContents',
  //   label: 'Type Of Contents',
  //   fieldType: 'dropdown',
  //   placeholder: 'Type of Contents',
  //   type: 'text',
  //   options: [
  //     { value: 'merchandise', label: 'Merchandise' },
  //     { value: 'documents', label: 'Documents' },
  //     { value: 'gift', label: 'Gift' },
  //     { value: 'returnedGoods', label: 'Returned Goods' },
  //     { value: 'sample', label: 'Sample' },
  //     { value: 'other', label: 'Other' },
  //   ],
  //   step: 2,
  //   formGroup: 2,
  // },
  {
    name: 'descriptionOfContents',
    label: 'Item Description',
    placeholder: 'Ex: T-Shirts',
    type: 'text',
    fieldType: 'text',
    step: 2,
    formGroup: 2,
  },
  {
    name: 'retailValue',
    label: 'Retail Value',
    placeholder: 'Ex: $500',
    type: 'number',
    fieldType: 'text',
    step: 2,
    formGroup: 2,
  },
  {
    name: 'currency',
    label: 'Currency',
    fieldType: 'dropdown',
    placeholder: 'Currency',
    type: 'text',
    options: [
      { value: 'USD', label: 'USD' },
      { value: 'CAD', label: 'CAD' },
    ],
    step: 2,
    formGroup: 2,
  }, {
    name: 'length',
    label: 'Length',
    fieldType: 'text',
    type: 'number',
    placeholder: 'Length',
    step: 2,
    formGroup: 3,
    optional: true,
  },
  {
    name: 'width',
    label: 'Width',
    fieldType: 'text',
    type: 'number',
    placeholder: 'Width',
    step: 2,
    formGroup: 3,
    optional: true,
  },
  {
    name: 'height',
    label: 'Height',
    fieldType: 'text',
    type: 'number',
    placeholder: 'Height',
    step: 2,
    formGroup: 3,
    optional: true,
  },
  {
    name: 'sizeUnit',
    label: 'Size Unit',
    fieldType: 'dropdown',
    type: 'text',
    placeholder: 'Unit',
    options: [
      { value: 'centimeter', label: 'cm' },
      { value: 'inch', label: 'inch' },
    ],
    step: 2,
    formGroup: 3,
  },
  {
    name: 'weight',
    label: 'Weight',
    placeholder: 'Weight',
    fieldType: 'text',
    type: 'number',
    step: 2,
    formGroup: 4,
  },
  {
    name: 'weightUnit',
    label: 'Weight Unit',
    fieldType: 'dropdown',
    placeholder: 'Unit',
    type: 'text',
    options: [
      { value: 'ounce', label: 'ounce' },
      { value: 'pound', label: 'pound' },
      { value: 'gram', label: 'gram' },
      { value: 'kilogram', label: 'kilogram' },
    ],
    step: 2,
    formGroup: 4,
  },
  {
    name: 'shipDate',
    label: 'Pickup Date',
    fieldType: 'date',
    type: 'text',
    placeholder: 'Pickup Date',
    step: 2,
    formGroup: 4,
  },
  {
    name: 'rememberInfo',
    label: 'Remember information for next time',
    fieldType: 'checkbox',
    type: 'text',
    placeholder: 'Remember Info',
    step: 2,
    formGroup: 5,
  },
  // Hide from UI for time being
  // {
  //   name: 'packageType',
  //   label: 'Package Type',
  //   fieldType: 'radio',
  //   placeholder: '',
  //   type: 'text',
  //   options: [
  //     { value: 'thickEnvelope', label: 'Thick Envelope', iconComponent: Mail },
  //     { value: 'parcel', label: 'Parcel', iconComponent: Package },
  //   ],
  //   step: 3,
  //   formGroup: 1,
  // },
  {
    name: 'rateId',
    label: 'Ship Rate',
    fieldType: 'radio',
    type: 'text',
    placeholder: 'Ship Rate',
    options: [
      { value: 'thickEnvelope', label: 'Thick Envelope' },
      { value: 'parcel', label: 'Parcel' },
    ],
    step: 3,
    formGroup: 1,
  },
]

export const accountEditFieldsArray: Array<FieldType> = [
  {
    name: 'firstName',
    label: 'First Name',
    type: 'text',
    fieldType: 'text',
    placeholder: 'First Name',
    step: 1,
    formGroup: 1,
  },
  {
    name: 'lastName',
    label: 'Last Name',
    type: 'text',
    fieldType: 'text',
    placeholder: 'Last Name',
    step: 1,
    formGroup: 1,
  },
  {
    name: 'addressLine1',
    label: 'Address Line 1',
    type: 'text',
    fieldType: 'text',
    placeholder: 'Address line 1',
    step: 1,
    formGroup: 2,
    customValidation: 'address',
  },
  {
    name: 'addressLine2',
    label: 'Apartment, suite, unit, building, floor, etc.',
    type: 'text',
    fieldType: 'text',
    placeholder: 'Address line 2',
    step: 1,
    formGroup: 3,
    customValidation: 'address',
    optional: true,
  },
  {
    name: 'city',
    label: 'City',
    type: 'text',
    fieldType: 'text',
    placeholder: 'City',
    step: 1,
    formGroup: 4,
    customValidation: 'address',
  },
  {
    name: 'country',
    label: 'Country',
    type: 'text',
    fieldType: 'dropdown',
    placeholder: 'Country',
    options: [
      { value: 'US', label: 'United States' },
      { value: 'CA', label: 'Canada' },
    ],
    step: 1,
    formGroup: 4,
    customValidation: 'address',
  },
  {
    name: 'state',
    label: 'State',
    type: 'text',
    fieldType: 'dropdown',
    placeholder: 'State',
    options: statesOptions.concat(provincesOptions),
    step: 1,
    formGroup: 5,
    customValidation: 'address',
  },
  {
    name: 'zip',
    label: 'Zip',
    type: 'text',
    fieldType: 'text',
    placeholder: 'Zip',
    step: 1,
    formGroup: 5,
    customValidation: 'address',
  },
  {
    name: 'phoneNumber',
    label: 'Phone Number',
    type: 'text',
    fieldType: 'text',
    placeholder: 'Phone number',
    step: 1,
    formGroup: 6,
  },
  {
    name: 'override',
    label: 'Use same address for billing and business',
    type: 'text',
    fieldType: 'checkbox',
    placeholder: 'Use same address for billing and business',
    step: 1,
    formGroup: 7,
  },
]

export const MultiStepFormPropsJson: {
  validationSchemas: Array<AnyObjectSchema>,
  fieldsArray: Array<FieldType>,
  successAnimation: any,
  stepAnimation: any,
} = {
  fieldsArray: fieldsArray,
  validationSchemas: shipmentFormValidationSchemaArray,
  successAnimation: successConfetti,
  stepAnimation: deliveryTruck,
}

export const MultiStepFormInitialValues = {
  recipientName: '',
  addressLine1: '',
  city: '',
  country: 'US',
  state: '',
  zip: '',
  phoneNumber: '',
  typeOfContents: 'merchandise',
  descriptionOfContents: '',
  retailValue: '',
  currency: '',
  packageType: 'parcel',
  weight: '',
  weightUnit: 'ounce',
  length: '',
  width: '',
  height: '',
  sizeUnit: 'inch',
  shipFrom: '',
  shipDate: new Date(new Date().setDate(new Date().getDate() + 1)).toISOString(),
  rememberInfo: true,
  military: false,
}

export const AccountEditFormInitialValues = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  addressLine1: '',
  city: '',
  country: 'US',
  state: '',
  zip: '',
  override: false,
}
