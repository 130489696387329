import { Field } from 'formik';
import './Checkbox.scss';

type CheckboxProps = {
  label: string;
  name: string;
};

const Checkbox = (props: CheckboxProps): JSX.Element => {
  const { name, label } = props;

  return (
    <div className='checkbox'>
      <label
        className='checkbox__label'
        htmlFor={`checkbox-${name}`}
      >
        <Field type='checkbox' name={name} id={`checkbox-${name}`}/>
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
