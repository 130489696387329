import { FunctionComponent } from 'react';
import './CreditSummary.scss';
import Card from '../Card/Card';
import PaymentService from '../../services/payment-service';
import { useHistory, useLocation } from 'react-router-dom';
import { getDecimal } from '../../services/helper';

type CreditSummaryCardProps = {
  available?: number;
  pending?: number;
};

const CreditSummaryCard: FunctionComponent<CreditSummaryCardProps> = ({
  available = 0,
  pending = 0,
}): JSX.Element => {
  const history = useHistory();
  const location = useLocation();

  const handleOpenModal = () => {
    PaymentService.openModal('', null);
    history.push({
      pathname: location.pathname,
      state: { background: location },
    })
  }

  return (
    <Card
      title='Credits'
      actionLabel='Add Credits'
      actionFunc={handleOpenModal}
    >
      <div className='credit-summary-row'>
        <div className='credit-summary-row__text'>Available</div>
        <div className='credit-summary-row__value'>{`$${getDecimal(available)}`} CAD</div>
      </div>
      <div className='credit-summary-row'>
        <div className='credit-summary-row__text'>Pending</div>
        <div className='credit-summary-row__value'>{`$${getDecimal(pending)}`} CAD</div>
      </div>
    </Card>
  );
};

export default CreditSummaryCard;
