import { FunctionComponent, useCallback } from 'react';
import cx from 'classnames';
import './Tabs.scss';

type TabTitleProps = {
  title: string;
  index: number;
  selectedTab: number;
  setSelectedTab: (index: number) => void;
};

const TabTitle: FunctionComponent<TabTitleProps> = ({
  title,
  index,
  selectedTab,
  setSelectedTab,
}): JSX.Element => {
  const onClick = useCallback(() => {
    setSelectedTab(index)
  }, [setSelectedTab, index]);

  return (
    <li className={cx({ 'tabs__labels--selected': selectedTab === index })}>
      <button className={cx('tabs__labels__btn')} onClick={onClick}>
        {title}
      </button>
    </li>
  );
};

export default TabTitle;
