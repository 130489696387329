import { useState, useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useDetectOutsideClick = (ref: React.RefObject<HTMLDivElement>, initialState: boolean): [boolean, (isActive: boolean) => void] => {
  const [isActive, setIsActive] = useState(initialState);

  useEffect(() => {
    const pageClickEvent = (e: MouseEvent) => {
      const target = e.target as HTMLElement;

      if (ref && ref.current && !ref.current.contains(target)) {
        setIsActive(!isActive);
      }
    };

    if (isActive) {
      window.addEventListener('click', pageClickEvent);
    }

    return () => {
      window.removeEventListener('click', pageClickEvent);
    }

  }, [isActive, ref]);
  return [isActive, setIsActive];
}

const initBeforeUnLoad = (showExitPrompt: boolean) => {
  window.onbeforeunload = event => {
    if (showExitPrompt) {
      const e = event || window.event;

      e.preventDefault();
      if (e) {
        e.returnValue = '';
      }
      return '';
    }
  };
};

export const useExitPrompt = (bool: boolean): [boolean, (showExitPrompt: boolean) => void] => {
  const [showExitPrompt, setShowExitPrompt] = useState(bool);

  window.onload = () => {
    initBeforeUnLoad(showExitPrompt);
  };

  useEffect(() => {
    initBeforeUnLoad(showExitPrompt);
  }, [showExitPrompt]);

  return [showExitPrompt, setShowExitPrompt];
}