import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';
import { validationAddress, INVALID_ADDRESS_MSG } from './shipmentFormValidationSchemas';
import { PHONE_REGEXP, US_ZIP_REGEX, CA_ZIP_REGEX } from './validationRegex';

export const accountEditFormValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('First Name is required'),
  lastName: Yup.string()
    .required('Last Name is required'),
  addressLine1: Yup.string().required('Required'),
  addressLine2: Yup.string(),
  city: Yup.string().required('Required'),
  country: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  zip: Yup.string().required('Required')
  .when('country', {
    is: 'US',
    then: Yup.string().matches(US_ZIP_REGEX, 'Invalid Zip'),
  })
  .when('country', {
    is: 'CA',
    then: Yup.string().matches(CA_ZIP_REGEX, 'Invalid Zip'),
  }),
  phoneNumber: Yup.string().matches(PHONE_REGEXP, 'Invalid Phone Format'),
}).test({
  name: 'addressValid',
  test: async function (values: AnyObject) {
    const isValid = await validationAddress(values);

    return isValid ? true : this.createError({
      path: 'address',
      message: INVALID_ADDRESS_MSG,
    })
  },
});