import Modal from 'react-modal';
import ShipmentService from '../../services/shipment-service';
import Button from '../Button/Button';
import Card from '../Card/Card';
import './ProceedWithoutValidationModal.scss';
import Banner from '../Banner/Banner';

// Apply once for accessibility needs
const root = document.getElementById('root');

if (root) {
  Modal.setAppElement(root);
}

const ProceedWithoutValidationModal = (): JSX.Element => {

  return (
    <Modal
      isOpen
      className='proceed-without-validation-modal'
      overlayClassName='proceed-without-validation-modal-overlay'
      onRequestClose={() => ShipmentService.closeModal()}
    >
      <div>
        <Card title='Proceed Without Validation'>
          <Banner type='alert' text={'The entered address cannot be verified by USPS.'} />
          Improperly labeled mail pieces may progress slower
          through the mail system and may be returned to sender
          if USPS is unable to deliver the item.
          Do you still wish to proceed?
          <div className='button-group'>
            <Button type='secondary' onClick={() => ShipmentService.closeModal()}>
              No
            </Button>
            <Button type='primary' onClick={() => ShipmentService.updateProceedWithoutValidation()}>
              Yes
            </Button>
          </div>
        </Card>
      </div>
    </Modal>
  );
};

export default ProceedWithoutValidationModal;
