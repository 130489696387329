/* eslint-disable camelcase */
import { Stripe, StripeCardElement } from '@stripe/stripe-js';
import ApiService from './api-service';
import AuthService from './auth-service';
import DBService from './db-service';
import ModalService, { MODAL_STATE } from './modal-service';

class PaymentServiceClass {
  private static instance: PaymentServiceClass;

  private isModalOpen = false;

  private infoText = '';

  private handlePostPayment: ((error?: string) => Promise<void>) | undefined | null;

  public static getInstance(): PaymentServiceClass {
    if (!PaymentServiceClass.instance) {
      PaymentServiceClass.instance = new PaymentServiceClass();
    }
    return PaymentServiceClass.instance;
  }

  openModal(infoText = '', handlePostPayment: ((error?: string) => Promise<void>) | undefined | null) {
    this.isModalOpen = true;
    if (infoText) {
      this.infoText = infoText;
      this.handlePostPayment = handlePostPayment;
    }
    ModalService.openModal(MODAL_STATE.PAYMENT_MODAL);
  }

  closeModal() {
    this.isModalOpen = false;
    this.infoText = '';
    ModalService.closeModal();
  }

  getInfoText() {
    return this.infoText;
  }

  getHandlePostPayment () {
    return this.handlePostPayment;
  }

  async fetchStripeKey() {
    try {
      const apiResult = await ApiService.post('fetchStripeKey', {});

      if (apiResult.err) {
        return null;
      } else {
        return apiResult.data;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async createPayment(stripe: Stripe, cardElement: StripeCardElement, amount: number) {
    const amountInCents = Math.round(amount * 100);

    try {
      const apiResult = await ApiService.post('createPaymentIntent', { amount: amountInCents, userId: AuthService.getUserId()});

      if (apiResult.err) {
        return null;
      }

      console.log('Payment intent', apiResult.data);
      const result = await stripe.confirmCardPayment(apiResult.data?.data?.client_secret, {
        payment_method: {
          card: cardElement,
        },
      });

      if (result.error) {
        // Show error to your customer (e.g., insufficient funds)
        console.error(result.error.message);
        return result;
      } else if (result.paymentIntent.status === 'succeeded') {
        //update balance
        const credits = amount / 1.13;

        await DBService.updateUserBalance(credits);
        return result;
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}
const PaymentService = PaymentServiceClass.getInstance();

export default PaymentService;
