import { ShipmentModel } from '../models/shipment-model';
import { FormikValues } from 'formik';
import ApiService from './api-service';
import AuthService from './auth-service';
import CarrierService from './carrier-service';
import DBService from './db-service';
import ModalService, { MODAL_STATE } from './modal-service';
import { UserModel } from '../models/user-model';
import { RowData } from '../lib/table/summaryCardCols';
import firebase from 'firebase';

class ShipmentServiceClass {
  private static instance: ShipmentServiceClass;

  private currentShipmentId: string | undefined;

  private currentSelectedIds: Array<string> = [];

  private currentLabelUrl: string | undefined;

  private currentShipment: ShipmentModel | undefined

  private lastVisibleShipment: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData> | undefined | null;

  private handleProceed: (() => void) | undefined;

  public static getInstance(): ShipmentServiceClass {
    if (!ShipmentServiceClass.instance) {
      ShipmentServiceClass.instance = new ShipmentServiceClass();
    }
    return ShipmentServiceClass.instance;
  }

  openModal(shipmentId: string, labelUrl: string) {
    this.currentShipmentId = shipmentId;
    this.currentLabelUrl = labelUrl;
    ModalService.openModal(MODAL_STATE.LABEL_PRINT_MODAL);
  }

  getHandleProceed() {
    return this.handleProceed;
  }

  openCreateSetModal(selectedRows: RowData[]) {
    const staleShipments = new Set();
    const seenDates = new Set();
    let hasMultiplePickupDates = false;

    this.currentSelectedIds = selectedRows.map((s: RowData): string => {
      const date = new Date(s.estimatedDeliveryDate || 0);
      const dateString = date.toDateString();

      if (seenDates.size > 0 && !seenDates.has(dateString)) {
        hasMultiplePickupDates = true;
      }

      seenDates.add(dateString);

      if (date < new Date()) {
        staleShipments.add(s.orderNumber);
      }

      return s.shipmentId || '';
    });

    if (hasMultiplePickupDates && selectedRows.length > 1) {
      throw new Error('Unable to create batch with multiple pickup dates: ' + Array.from(seenDates).join(', '));
    }

    if (staleShipments.size > 0) {
      throw new Error('Unable to create batch with stale shipments. Please remove ' + Array.from(staleShipments).join(', ') + ' and try again.');
    }

    ModalService.openModal(MODAL_STATE.CREATE_SET_MODAL);
  }

  openCancelLabelModal(data: ShipmentModel) {
    this.currentShipment = data;
    ModalService.openModal(MODAL_STATE.CANCEL_LABEL_MODAL);
  }

  openProceedWithoutValidationModal(handleProceed: any) {
    this.handleProceed = handleProceed;
    ModalService.openModal(MODAL_STATE.PROCEED_WITHOUT_VALIDATION);
  }

  updateProceedWithoutValidation() {
    ModalService.closeModal();

    const handleProceedFunc = this.handleProceed;

    handleProceedFunc && handleProceedFunc();
  }

  closeModal() {
    this.currentShipmentId = undefined;
    this.currentLabelUrl = undefined;
    this.currentShipment = undefined;
    ModalService.closeModal();
  }

  async createShipment(shipment: ShipmentModel) {
    try {
      await DBService.createShipment(shipment);
      const oldUser = AuthService.getUser();

      if (oldUser) {
        const newUser: UserModel = {
          ...oldUser,
          totalPendingShipments: (oldUser?.totalPendingShipments || 0) + 1,
          totalShipments: (oldUser?.totalShipments || 0) + 1,
        };

        AuthService.setUser(newUser);
      }
    } catch (error) {
      return error;
    }
  }

  async createLabel(shipment: ShipmentModel): Promise<ShipmentModel | { errorName: string, message: string } | null> {
    try {
      // TODO - process payment
      const { data } = await ApiService.post('createLabelFromRate',
        {
          rateId: shipment.shipEngine.rateId,
          totalAmount: shipment.cost || 0,
        });

      if (data?.err?.details) {
        return {
          message: data.err.details.message,
          errorName: data.err.details.errorName,
        }
      }

      const resultData = data.data;

      // Populate additional details
      shipment.labelUrl = resultData.pdfLink;
      shipment.trackingNumber = resultData.trackingNumber || '';
      shipment.shipEngine.carrierId = resultData.carrierId;
      shipment.shipEngine.labelId = resultData.labelId;
      shipment.shipEngine.shipmentId = resultData.shipmentId;

      // Create shipment in the DB
      await this.createShipment(shipment);

      const currentUser = AuthService.getUser();

      if (currentUser) {
        const newUser: UserModel = {
          ...currentUser,
          balance: (currentUser?.balance || 0) - (shipment.cost || 0),
        };

        AuthService.setUser(newUser);
      }

      return shipment;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async getShipment(shipmentId?: string) {
    try {
      if (!shipmentId) {
        return null;
      }

      return await DBService.getShipment(shipmentId);
    } catch (error) {
      return error;
    }
  }

  async getLatestShipments() {
    return await DBService.getLatestShipments();
  }

  getCurrentLabelUrl() {
    return this.currentLabelUrl;
  }

  getCurrentSelectedIds() {
    return this.currentSelectedIds;
  }

  getCurrentShipment() {
    return this.currentShipment;
  }

  openLabelUrl(url: string) {
    const win = window.open(url, '_blank');

    if (win) {
      win.focus();
    }
  }

  async getShipmentRates(values: FormikValues) {
    try {
      return await ApiService.post('getRates', {
        ...values,
        carrierIds: await CarrierService.getCarriers(),
      });
    } catch (error) {
      console.error(error);
      return { data: null };
    }
  }
  async getAllCancelledShipments(page: number, pageSize: number) {
    try {
      return await DBService.getAllCancelledShipments(page, pageSize);
    } catch (error) {
      return [];
    }
  }

  async searchAllCancelledShipments(searchText: string) {
    try {
      return await DBService.searchAllCancelledShipments(searchText);
    } catch (error) {
      return [];
    }
  }

  async getAllPendingShipments(page: number, pageSize: number) {
    try {
      return await DBService.getAllPendingShipments(page, pageSize);
    } catch (error) {
      return [];
    }
  }

  async searchAllPendingShipments(searchText: string) {
    try {
      return await DBService.searchAllPendingShipments(searchText);
    } catch (error) {
      return [];
    }
  }

  async searchLatestShipments(searchText: string) {
    try {
      return await DBService.searchLatestShipments(searchText);
    } catch (error) {
      return [];
    }
  }

  async voidLabel() {
    try {
      const result = await ApiService.post('voidLabel', {
        labelId: this.currentShipment?.shipEngine.labelId,
        id: this.currentShipment?.id,
        userId: AuthService.getUserId(),
      })

      if (result?.data?.err) {
        return result.data.err?.details.message;
      }

      if (result) {
        const currentUser = AuthService.getUser();

        AuthService.setUser({
          ...result.data,
          totalCancelledShipments: (currentUser?.totalCancelledShipments || 0) + 1,
        });
      }
    } catch (error) {
      return [];
    }
  }

  async getAllShipmentsWithBatches() {
    try {
      return await DBService.getAllShipmentsWithBatches();
    } catch (error) {
      return [];
    }
  }

  async updateShipmentLabelViewed() {
    try {
      if (this.currentShipmentId) {
        const labelUrlLink = this.getCurrentLabelUrl();

        if (labelUrlLink) {
          this.openLabelUrl(labelUrlLink);
        }

        return await DBService.updateShipmentLabelViewed(this.currentShipmentId);
      }
    } catch (error) {
      console.error(error);
    }
  }

  setLastVisibleShipment(lastVisible: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData> | null | undefined) {
    this.lastVisibleShipment = lastVisible;
  }

  getLastVisibleShipment() {
    return this.lastVisibleShipment;
  }
}

const ShipmentService = ShipmentServiceClass.getInstance();

export default ShipmentService;
