/* eslint-disable camelcase */
import ApiService from './api-service';

class ApiKeyService {
  private static instance: ApiKeyService;

  public static getInstance(): ApiKeyService {
    if (!ApiKeyService.instance) {
      ApiKeyService.instance = new ApiKeyService();
    }
    return ApiKeyService.instance;
  }

  async fetchStripeKey() {
    try {
      const apiResult = await ApiService.post('fetchStripeKey', {});

      if (apiResult.data.err) {
        return null;
      } else {
        return apiResult.data;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}
const PaymentService = ApiKeyService.getInstance();

export default PaymentService;
