import Card from '../Card/Card';
import debounce from 'lodash.debounce';
import { batchColumnDefinition, batchColumnType, RowData } from '../../lib/table/summaryCardCols';
import { useCallback, useEffect, useState } from 'react';
import BatchService from '../../services/batch-service';
import ShipmentService from '../../services/shipment-service';
import DocumentService from '../../services/document-service';
import { ShipmentModel } from '../../models/shipment-model';
import Table from '../MaterialTable/PaginationTable';
import Loader from '../Loader/Loader';
import AuthService from '../../services/auth-service';
import { BATCHED_PAGE_SIZE, DEBOUNCE_MS } from '../MaterialTable/tableHelper';
import Banner from '../Banner/Banner';
import cx from 'classnames';
import { statusToLabel } from '../../services/helper';

const BatchCard = (): JSX.Element => {
  const [data, setData] = useState<batchColumnType[]>();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isDataLoading, setDataLoading] = useState<boolean>(true);
  const [pagination, setPagination] = useState<{ page: number, pageSize: number }>({ page: 0, pageSize: BATCHED_PAGE_SIZE });
  const [debouncedSearchText, setDebouncedSearchText] = useState('');

  const getRowData = async () => {
    setDataLoading(true);

    let rowData = (debouncedSearchText !== '') ? await BatchService.searchBatches(debouncedSearchText) : await BatchService.getAllBatches(pagination.page, pagination.pageSize);

    // retry
    if (!rowData) {
      await new Promise(resolve => setTimeout(resolve, 1000));
      rowData = (debouncedSearchText !== '') ? await BatchService.searchBatches(debouncedSearchText) : await BatchService.getAllBatches(pagination.page, pagination.pageSize);
    }

    const shipmentData = await ShipmentService.getAllShipmentsWithBatches();
    const shipmentDataMap: { [key: string]: ShipmentModel } = {};

    shipmentData.forEach(d => {
      if (d.shipmentId) {
        shipmentDataMap[d.shipmentId] = d;
      }
    });

    if (rowData) {
      const newData = rowData.map<batchColumnType>((row: any) => {
        return row?.shipmentIds.map((s: string) => {
          return shipmentDataMap[s] && {
            ...shipmentDataMap[s],
            id: shipmentDataMap[s]?.id, //this is used for parentchild data
            orderNumber: shipmentDataMap[s]?.id || 'N/A',
            shipmentId: shipmentDataMap[s]?.shipmentId || 'N/A',
            trackingNumber: shipmentDataMap[s]?.trackingNumber || 'N/A',
            destination: shipmentDataMap[s].shipTo.city + ', ' + shipmentDataMap[s].shipTo.state,
            label: statusToLabel(shipmentDataMap[s]?.status),
            batchId: shipmentDataMap[s]?.batchId || row?.batchId || 'N/A',
            customer: shipmentDataMap[s]?.shipTo.name || 'N/A',
            parentId: row?.batchId || 'N/A',
          }
        }).concat([{
          batchId: row?.batchId || 'N/A',
          batchStatus: row?.status,
          id: row?.batchId || 'N/A', //this is used for parentchild data
          createdAt: row?.createdAt || 0,
        }])
      });

      setData(newData.flat().filter(c => c !== undefined));
      setDataLoading(false);
    } else {
      setData([]);
    }
  };

  useEffect(() => {
    getRowData();
  }, [pagination, debouncedSearchText]);

  useEffect(() => {
    return () => {
      BatchService.setLastVisibleBatch(null);
    }
  }, []);

  const fetchDataByPage = (page: number, pageSize: number) => {
    setPagination({ page, pageSize });
  };

  const onSearchChange = (searchText: string) => {
    searchDebounce(searchText);
  }

  const searchDebounce = useCallback(
    debounce((_searchText: string) => {
      setDebouncedSearchText(_searchText);
    }, DEBOUNCE_MS),
    [],
  );

  const createDocuments = async (values: any) => {
    setError('');
    setLoading(true);
    setSuccess(false);

    try {
      const result = await DocumentService.createDocuments(values);

      if (result) {
        if (result?.err?.details) {
          setError(result.err?.details?.message || '');
        } else {
          setSuccess(true);
        }

        setLoading(false);
      }
    } catch (error) {
      setError(error);
      setSuccess(false);
    }
  }

  const shouldDisableButtonFunc = (selectedRows: batchColumnType[]): boolean => {
    const allSent = selectedRows
      .filter((r: batchColumnType) => r.batchStatus)
      .some((r: batchColumnType) => r.batchStatus === 'SENT');

    return selectedRows.length === 0 || allSent;
  }

  const pageSize = debouncedSearchText !== '' ? 10 : pagination.pageSize;
  const page = debouncedSearchText !== '' ? 0 : pagination.page;

  const getTotalCount = () => {
    if (debouncedSearchText) {
      return data?.filter(c => !c.parentId).length || 0;
    }
    return AuthService.getUser()?.totalBatches || 0;
  }

  return (
    <Card title={`Batched Shipments`}>
      {
        error && <Banner
          text={error}
          type='error'
        />
      }
      {
        success && <Banner
          text='Documents were successfully generated.'
          type='success'
        />
      }
      {!data ? <Loader /> : (
        <Table
          rowData={data}
          groupByFieldFunc={(row: RowData, rows: RowData[]) => rows.find((a: RowData) => a?.id === row?.parentId)}
          columnDefinition={batchColumnDefinition}
          actionBtnText={isLoading ? (
            <div className='d-flex'>
              <div className={cx('no-wrap', 'mr-1')}>{'Please Wait'}</div>
              <Loader />
            </div>
          ) : 'Generate Documents'}
          actionFunc={values => createDocuments(values)}
          page={page}
          isLoading={isDataLoading}
          pagination={pageSize === BATCHED_PAGE_SIZE}
          pageSize={Math.min(pageSize, data.length + 1)}
          fetchDataByPage={fetchDataByPage}
          onSearchChange={onSearchChange}
          shouldDisableButtonFunc={props => isLoading || shouldDisableButtonFunc(props.selectedRows)}
          totalCount={getTotalCount()}
        />
      )}
    </Card>
  );
};

export default BatchCard;
