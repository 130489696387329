import { FunctionComponent } from 'react';
import MultiStepForm from '../../components/MultiStepForm/MultiStepForm';
import { MultiStepFormPropsJson } from '../../lib/Form/fieldsArray';

const CreateShipment: FunctionComponent = (): JSX.Element => {
  return (
    <MultiStepForm {...MultiStepFormPropsJson}/>
  );
};

export default CreateShipment;
