import { FunctionComponent } from 'react';
import Login from '../../pages/Auth/Login';
import Dashboard from '../../pages/Dashboard/Dashboard';
import TodoPage from '../../pages/TodoPage/TodoPage';
import { Home, Settings as Gear, User, Package, Icon } from 'react-feather';
import SignUp from '../../pages/Auth/SignUp';
import Shipment from '../../pages/Shipment/Shipment';
import CreateShipment from '../../pages/Shipment/CreateShipment';
import Account from '../../pages/Account/Account';
// import Settings from '../../pages/Settings/Settings';
import ForgotPassword from '../../pages/Auth/ForgotPassword';
import ResetPassword from '../../pages/Auth/ResetPassword';
import ViewShipment from '../../pages/Shipment/ViewShipment';

export type BrzRouteProps = {
  component: FunctionComponent;
  title: string;
  path: string;
  isPublic?: boolean;
  parent?: BrzRouteProps;
  icon?: Icon;
};

// Navbar Routes
const dashboardRoute = {
  path: '/',
  title: 'Dashboard',
  component: Dashboard,
  icon: Home,
};

const shipmentRoute = {
  path: '/shipment',
  title: 'Shipment',
  component: Shipment,
  icon: Package,
};

const accountRoute = {
  path: '/account',
  title: 'Account',
  component: Account,
  icon: User,
};

const settingsRoute = {
  path: '/settings',
  title: 'Settings',
  // component: Settings,
  component: Account, // temporary change
  icon: Gear,
};

export const navBarRoutes: BrzRouteProps[] = [
  dashboardRoute,
  shipmentRoute,
  // accountRoute,
  settingsRoute,
];

const pageRoutes: BrzRouteProps[] = [
  // Unauthenticated
  { path: '/login', title: 'Login', component: Login, isPublic: true },
  { path: '/signup', title: 'SignUp', component: SignUp, isPublic: true },
  {
    path: '/forgot-password',
    title: 'Forgot Password',
    component: ForgotPassword,
    isPublic: true,
  },
  {
    path: '/password-reset',
    title: 'Dashboard',
    component: ResetPassword,
    isPublic: true,
  },

  // Dashboard
  { path: '/', title: 'Dashboard', component: Dashboard },

  // Shipment
  shipmentRoute,
  { path: '/shipment/create', title: 'Create', component: CreateShipment, parent: shipmentRoute },
  { path: '/shipment/view/:id', title: 'View', component: ViewShipment, parent: shipmentRoute },

  // Account
  accountRoute,
  {
    path: '/account/edit',
    title: 'Edit Account',
    component: TodoPage,
    parent: accountRoute,
  },

  // Settings
  settingsRoute,
  {
    path: '/settings/edit',
    title: 'Edit Settings',
    component: TodoPage,
    parent: accountRoute,
  },
];

export default pageRoutes;
