/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode, useState, useEffect } from 'react';
import { Form, Formik, FormikErrors, FormikTouched, FormikValues } from 'formik';
import { AnyObjectSchema } from 'yup';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import { Circle } from 'react-feather';
import { FieldType, MultiStepFormInitialValues } from '../../lib/Form/fieldsArray';
import { useExitPrompt } from '../../lib/hooks/hooks';
import { getDecimal } from '../../services/helper';
import { ShipmentModel } from '../../models/shipment-model';
import PersonalizationService, { defaultPersonalizationSettings, FormSettings } from '../../services/personalization-service';
import lottie, { AnimationItem } from 'lottie-web';
import Button from '../Button/Button';
import Card from '../Card/Card';
import Loader from '../Loader/Loader';
import Banner from '../Banner/Banner';
import Description from '../../forms/MultiForm/DescriptionForm';
import Recipient from '../../forms/MultiForm/RecipientForm';
import MultiStepFormContent from './MultiStepFormContent';
import freezeFirstFrame from '../../lib/lottie/freezeFirstFrame';
import EstimateLabel from '../../forms/MultiForm/EstimateLabelForm';
import ShipmentService from '../../services/shipment-service';
import AuthService from '../../services/auth-service';
import ApiService from '../../services/api-service';
import PaymentService from '../../services/payment-service';
import cx from 'classnames';
import './MultiStepForm.scss';

const FINAL_STEP = 3;
const MAX_CHAR_DIFF = 7;

type MultiStepFormProps = {
  validationSchemas: Array<AnyObjectSchema>,
  fieldsArray: Array<FieldType>,
  successAnimation: any,
  stepAnimation: any,
};

export type LabelDetails = {
  pdfLink: string,
  trackingNumber: string,
  shipmentId: string,
  carrierId: string,
};

export type RateEstimateType = {
  currency: string,
  totalAmount: number,
  rateId: string,
  estimatedDeliveryDate: string,
  packageType: string,
  carrierName: string,
};

const MultiStepForm = ({ validationSchemas, fieldsArray, successAnimation, stepAnimation }: MultiStepFormProps): JSX.Element => {
  const location = useLocation();
  const historyObject = useHistory();
  const [currentStep, setCurrentStep] = useState(1);
  const [formCompleted, setFormCompleted] = useState(false);
  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);
  const [ratesEstimateDetails, setRatesEstimateDetails] = useState<null | RateEstimateType[]>(null);
  const [labelDetails, setLabelDetails] = useState<null | LabelDetails>(null);
  const [error, setError] = useState<string>('');
  const [alert, setAlert] = useState<string>('');
  const [showLoading, setShowLoading] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [currentFormValues, setCurrentFormValues] = useState<null | FormikValues>();
  const [currentFormActions, setCurrentFormActions] = useState<null | any>();

  useEffect(() => {
    const element = document.querySelector('#multi-step-form__animation-icon');

    if (element) {
      lottie.destroy();
      const animation: AnimationItem = lottie.loadAnimation({
        container: element,
        animationData: stepAnimation,
      });

      freezeFirstFrame(animation);
    }
    if (currentStep !== FINAL_STEP) {
      setShowExitPrompt(true);
    }
    if (currentStep !== FINAL_STEP - 1 && error) {
      setError('');
    }
  }, [currentStep]);

  useEffect(() => {
    return () => {
      setShowExitPrompt(false);
    }
  }, []);

  useEffect(() => {
    if (formCompleted) {
      const successElement = document.querySelector('#multi-step-form--success');

      if (successElement) {
        lottie.loadAnimation({
          container: successElement,
          animationData: successAnimation,
          loop: false,
        });
      }
    }
  }, [formCompleted]);

  useEffect(() => {
    if (showPaymentModal) {

      historyObject.push({
        pathname: location.pathname,
        state: { background: location },
      });
    }
  }, [showPaymentModal]);

  const handleSubmit = async (values: FormikValues, actions: any) => {
    setAlert('');
    setError('');
    if (currentStep === FINAL_STEP) {
      await submitForm(values, actions);
    } else if (currentStep === FINAL_STEP - 1) {
      const { data } = await ShipmentService.getShipmentRates(values);

      if (data?.err?.details) {
        setError(data.err.details.message);
      } else {
        setRatesEstimateDetails(data);
        setCurrentStep(currentStep + 1);
        actions.setTouched({});
        actions.setSubmitting(false);
      }
    } else {
      setCurrentStep(currentStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  const handleOnPaste = async (event: ClipboardEvent, setFieldValue: any): Promise<void> => {
    event.preventDefault();
    const { clipboardData } = event;
    const value = clipboardData?.getData('text/plain') || '';

    if (value) {
      setShowLoading(true);

      const { data } = await ApiService.post('parseAddress', value);

      if (data) {
        if (data.name || data.company_name) {
          setFieldValue('recipientName', data.name || data.company_name);
        }
        setFieldValue('addressLine1', data.address_line1 || '');
        setFieldValue('addressLine2', data.address_line2 || '');
        setFieldValue('city', data.city_locality || '');
        setFieldValue('state', data.country_code === 'US' && data.state_province || '');
        setFieldValue('country', 'US');
        setFieldValue('zip', data.postal_code || '');
      }

      setShowLoading(false);

      delete data.address_residential_indicator;
      const valueCharLength = value.trim().length;
      const resultCharLength = Object.values(data).join(' ').trim().length;

      if (Math.abs(resultCharLength - valueCharLength) > MAX_CHAR_DIFF) {
        setAlert('Please double check the fields. There may be missing information needed from the address.');
      }
    }
  }

  const handlePostPayment = async (error = '') => {
    PaymentService.closeModal();
    setShowPaymentModal(false);
    if (error) {
      setError(error);
    } else if (currentFormValues && currentFormActions) {
      await handleSubmit(currentFormValues, currentFormActions);
    }
  }

  const submitForm = async (values: FormikValues, actions: any) => {
    let formSettings: FormSettings;

    const rate = ratesEstimateDetails?.find(estimate => estimate.rateId === values.rateId);

    if (rate) {
      const result = await ShipmentService.createLabel({
        userId: AuthService.getUserId(),
        createdAt: Date.now(),
        cost: rate?.totalAmount,
        estimatedDeliveryDate: rate?.estimatedDeliveryDate,
        status: 'READY',
        shipEngine: {
          rateId: rate?.rateId,
        },
        shipTo: {
          address: values.addressLine1,
          address2: values.addressLine2 || '',
          city: values.city,
          country: values.country,
          state: values.state,
          name: values.recipientName,
          postal: values.zip,
          phoneNumber: values.phoneNumber || '',
        },
        shipFrom: {
          name: 'Breeze Courier / ' + (values.shipFrom || AuthService.getBusinessProfileFullName()),
        },
        details: {
          typeOfContents: values.typeOfContents,
          descriptionOfContents: values.descriptionOfContents,
          retailValue: values.retailValue,
          currency: values.currency,
          packageType: values.packageType || 'parcel',
          weight: values.weight,
          weightUnit: values.weightUnit,
          length: values.length,
          width: values.width,
          height: values.height,
          sizeUnit: values.sizeUnit,
        },
      });

      if (result && 'errorName' in result) {
        if (result.errorName === 'ERROR_INSUFFICIENT_BALANCE') {
          setShowPaymentModal(true);
          const infoText = `You will need a minimum of $${getDecimal(rate.totalAmount)} credits to complete this transaction`;

          PaymentService.openModal(infoText, handlePostPayment);
          setCurrentFormValues(values);
          setCurrentFormActions(actions);
        } else {
          setError(result.message);
        }
      } else if (result && !('errorName' in result)) {
        const shipment = result as ShipmentModel;

        if (values.rememberInfo) {
          formSettings = values as FormSettings;
          formSettings.rateId = '';
        } else {
          formSettings = { ...defaultPersonalizationSettings.forms, rateId: '', rememberInfo: false };
        }

        PersonalizationService.setPersonalizationFormSettings(formSettings);

        actions.setSubmitting(false);
        actions.resetForm({
          values: {
            ...MultiStepFormInitialValues,
            ...formSettings,
            shipDate: getShipDate(new Date(MultiStepFormInitialValues.shipDate), new Date(formSettings.shipDate)),
          },
        });

        if (shipment.labelUrl) {
          ShipmentService.openLabelUrl(shipment.labelUrl);
        }

        setLabelDetails({
          pdfLink: shipment.labelUrl || '',
          trackingNumber: shipment.trackingNumber || '',
          shipmentId: shipment.id || '',
          carrierId: shipment?.shipEngine?.carrierId || '',
        });
        setCurrentStep(currentStep + 1);
        setFormCompleted(true);
        setShowExitPrompt(false);
        setCurrentFormActions(null);
        setCurrentFormValues(null);
      }
    }
  }

  const handlePreviousStep = (setFieldValue: any): void => {
    if (currentStep === 1) {
      history.back();
    } else if (currentStep === FINAL_STEP + 1) {
      resetCurrentForm();
    } else {
      setCurrentStep(currentStep - 1);
    }

    // Reset the rate id before last step and after last step
    if (currentStep >= FINAL_STEP) {
      setFieldValue('rateId', '');
    }
  }

  const resetCurrentForm = (): void => {
    setFormCompleted(false);
    setCurrentStep(1);
    setRatesEstimateDetails(null);
    setLabelDetails(null);
    setShowExitPrompt(false);
  }

  const renderCurrentStep = (errors: FormikErrors<FormikValues>, touched: FormikTouched<FormikValues>, values: FormikValues, setFieldValue: any) => {
    const contentProps = {
      fieldsArray,
      errors,
      touched,
      step: currentStep,
      additionalValues: ratesEstimateDetails,
      handleOnPasteFunc: handleOnPaste,
      setFieldValue: setFieldValue,
    };

    switch (currentStep) {
      case 1:
        return <MultiStepFormContent component={Recipient} {...contentProps} />;
      case 2:
        return <MultiStepFormContent component={Description} {...contentProps} />;
      case 3:
        return <MultiStepFormContent component={EstimateLabel} {...contentProps} values={values} />;
      default:
        if (currentStep > FINAL_STEP && !formCompleted) {
          return <Loader />;
        }
        return;
    }
  }

  const renderPreviousStepBtn = (setFieldValue: any, isSubmitting: boolean) => {
    return currentStep !== 1 && (
      <Button disabled={isSubmitting} type='secondary' onClick={isSubmitting ? () => { /* do nothing */ } : () => handlePreviousStep(setFieldValue)}>
        {formCompleted ? 'Create Another Shipment' : 'Back'}
      </Button>
    );
  };

  const renderNextStepBtn = (isSubmitting: boolean) => (
    <Button disabled={isSubmitting} type='submit' to={formCompleted ? '/shipment' : undefined}>
      {formCompleted && 'Done'}
      {!formCompleted && currentStep < FINAL_STEP - 1 && 'Next'}
      {!formCompleted && currentStep === FINAL_STEP - 1 && (isSubmitting ? <Loader /> : 'Estimate Rates')}
      {!formCompleted && currentStep === FINAL_STEP && (isSubmitting ? <Loader /> : 'Confirm & Pay')}
      {!formCompleted && currentStep > FINAL_STEP && <Loader />}
    </Button >
  );

  const renderStepIndicator = (): ReactNode => {
    const stepTitles = ['Recipient', 'Package', 'Confirm'];

    return stepTitles.map((title, i) => {

      return (
        <div className='multi-step-form__indicator__icon-wrapper' key={`multi-step-from-title-${i}`}>
          <Circle
            key={`multi-step-form__indicator__icon-${i}`}
            className={cx(
              'multi-step-form__indicator__icon',
              {
                'multi-step-form__indicator__icon--filled': currentStep >= i + 1,
              },
            )}
            size={40}
          />
          <span className='multi-step-form__indicator__label'>{title}</span>
        </div>
      )
    })
  }

  const getShipDate = (initialDate: Date, savedDate: Date): Date => {
    //initial Date is always in future

    return savedDate > initialDate ? savedDate : initialDate
  }

  const getAddressError = (errors: any) => {
    return errors && errors['address'];
  }

  const openProceedWithoutValidationModal = () => {
    const handleProceedFunction = () => {
      setAlert('');
      setCurrentStep(currentStep + 1);
    }

    ShipmentService.openProceedWithoutValidationModal(handleProceedFunction);
  }

  const personalizationFormSettings = PersonalizationService.getPersonalizationFormSettings();

  if (!AuthService.isBillingProfileComplete() || !AuthService.isBusinessProfileComplete()) {
    return (
      <Card title='Incomplete Profile'>
        <Banner type='error' text='Please fill in billing and business details before proceeding.' />
        <div className='button-group'>
          <Button type='primary' to='/settings'>
            Go to Settings
          </Button>
        </div>
      </Card>
    );
  }

  return (
    <Card title='Create A New Shipment'>
      <Prompt
        when={!showPaymentModal && showExitPrompt}
        message='You have unsaved changes, are you sure you want to leave?'
      />
      <Formik
        initialValues={{
          ...MultiStepFormInitialValues,
          shipFrom: AuthService.getBusinessProfileFullName(),
          ...personalizationFormSettings,
          shipDate: getShipDate(new Date(MultiStepFormInitialValues.shipDate), new Date(personalizationFormSettings.shipDate)),
          rateId: '',
        }}
        validationSchema={validationSchemas[currentStep - 1]}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, errors, touched, values, setFieldValue }) => (
          <Form id='shipment-form' onChange={() => {
            setShowExitPrompt(true)
          }}>
            <div className='multi-step-form'>
              <div className='multi-step-form__animation--wrapper'>
                <div id='multi-step-form__animation-icon'
                  className={cx(
                    'multi-step-form__animation',
                    `multi-step-form__animation--move-${currentStep - 1}`,
                  )}
                />
              </div>
              <div className='multi-step-form__indicator'>
                <div className='multi-step-form__indicator__wrapper'>
                  {renderStepIndicator()}
                </div>
                <hr className='multi-step-form__indicator__line' />
              </div>
              {error && <Banner type='error' text={error} />}
              {alert && <Banner type='alert' text={alert} />}
              <div className='multi-step-form__form-content'>
                {showLoading && (
                  <div className='multi-step-form__loading-overlay'>
                    <Loader />
                  </div>
                )}
                {renderCurrentStep(errors, touched, values, setFieldValue)}
              </div>
              {formCompleted && labelDetails &&
                <div className='multi-step-form__success-content'>
                  <div id='multi-step-form--success' />
                  <div style={{ marginTop: '-1rem' }}><strong>Purchase successful!</strong></div>
                  <div >
                    <Button
                      type='primary'
                      onClick={() => ShipmentService.openModal(labelDetails?.shipmentId, labelDetails?.pdfLink)}
                    >
                      To view and print your label click here
                    </Button>
                  </div>
                  <div className='mt-4'><strong>Tracking Number:</strong></div>
                  <div>
                    {
                      !labelDetails?.trackingNumber ? 'N/A'
                        : labelDetails?.trackingNumber
                    }
                  </div>
                </div>}
              <div className='button-group'>
                {
                  getAddressError(errors) && currentStep === 1 &&
                  <Button type='secondary' onClick={() => openProceedWithoutValidationModal()}>
                    Proceed Without Validation
                  </Button>
                }
                {renderPreviousStepBtn(setFieldValue, isSubmitting)}
                {renderNextStepBtn(isSubmitting)}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Card >
  );
};

export default MultiStepForm;
