import { FunctionComponent } from 'react';
import breezeBg from '../../assets/breeze-bg.jpg';
import logo from '../../assets/logo.png';
import './AuthPage.scss';

const AuthPage: FunctionComponent = ({ children }): JSX.Element => {
  return (
    <div
      className='authpage'
      style={{
        backgroundImage: `linear-gradient(#37517e 0%, transparent 25%), url(${breezeBg})`,
      }}
    >
      <nav className='authpage__nav'>
        <div className='authpage__logo-wrapper'>
          <img src={logo} alt='Breeze Courier Logo' />
        </div>
      </nav>
      <main>
        <section className='authpage__content'>{children}</section>
      </main>
    </div>
  );
};

export default AuthPage;
