import { FunctionComponent } from 'react';
import ShipmentCard from '../../components/Widgets/ShipmentCard';
import BatchCard from '../../components/Widgets/BatchCard';
import CancelledShipmentCard from '../../components/Widgets/CancelledShipmentCard';

const Shipment: FunctionComponent = (): JSX.Element => {
  return (
    <div>
      <ShipmentCard summaryCard/>
      <BatchCard />
      <CancelledShipmentCard />
    </div>
  );
};

export default Shipment;
