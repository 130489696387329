import firebase from 'firebase/app';
import { auth } from '../firebase';
import { UserInfo, UserModel } from '../models/user-model';
import DBService from './db-service';
import ModalService, { MODAL_STATE } from './modal-service';
import Cookies from 'js-cookie';

const CURRENT_PATH_COOKIE = 'current-path';

class AuthServiceClass {
  private static instance: AuthServiceClass;

  private _subscribers: Array<(user: UserModel | undefined, loading: boolean) => void> = [];

  private currentUser?: UserModel;

  private loading = true;

  private modalType?: 'billingInfo' | 'businessInfo';

  public static getInstance(): AuthServiceClass {
    if (!AuthServiceClass.instance) {
      AuthServiceClass.instance = new AuthServiceClass();
    }
    return AuthServiceClass.instance;
  }

  isBusinessProfileComplete() {
    const businessProfile = this.getUserBusinessInfo();

    return (businessProfile?.firstName && businessProfile?.lastName && businessProfile?.addressLine1 && businessProfile?.country)
  }

  isBillingProfileComplete() {
    const billingProfile = this.getUserBillingInfo();

    return (billingProfile?.firstName && billingProfile?.lastName && billingProfile?.addressLine1 && billingProfile?.country)
  }

  getCurrentPathname() {
    return Cookies.get(CURRENT_PATH_COOKIE) || undefined;
  }

  getProfile(type: 'billingInfo' | 'businessInfo'): UserInfo | undefined {
    if (type === 'billingInfo') {
      return this.getUserBillingInfo();
    } else {
      return this.getUserBusinessInfo();
    }
  }

  getUserBusinessInfo() {
    return this.currentUser?.businessInfo;
  }

  getUserBillingInfo() {
    return this.currentUser?.billingInfo;
  }

  openModal(type: 'billingInfo' | 'businessInfo') {
    this.modalType = type;
    ModalService.openModal(MODAL_STATE.PROFILE_MODAL);
  }

  getModalType(): 'billingInfo' | 'businessInfo' {
    return this.modalType || 'billingInfo';
  }

  getUser() {
    return this.currentUser;
  }

  getUserId() {
    return auth.currentUser?.uid;
  }

  getLoading() {
    return this.loading;
  }

  getBusinessProfileFullName() {
    return `${this.currentUser?.businessInfo?.firstName || ''} ${this.currentUser?.businessInfo?.lastName || ''}`;
  }

  getBillingProfileFullName() {
    return `${this.currentUser?.billingInfo?.firstName || ''} ${this.currentUser?.billingInfo?.lastName || ''}`;
  }

  setUser(user: UserModel | undefined) {
    this.currentUser = user;
    this.notifyAll();
  }

  setLoading(loading: boolean) {
    this.loading = loading;
    this.notifyAll();
  }

  setCurrentPathname(path: string) {
    Cookies.set(CURRENT_PATH_COOKIE, path);
  }

  resetCurrentPathname() {
    Cookies.remove(CURRENT_PATH_COOKIE);
  }

  async emailLogin(email: string, password: string, rememberMe: boolean) {
    if (rememberMe) {
      auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    } else {
      auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
    }

    try {
      this.resetCurrentPathname();
      await auth.signInWithEmailAndPassword(email, password);
      this.setUser(await DBService.getCurrentUser());
    } catch (error) {
      return error;
    }
  }

  async emailSignup(email: string, password: string) {
    auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);

    try {
      const { user } = await auth.createUserWithEmailAndPassword(email, password);

      await DBService.createUser({
        id: user?.uid,
        createdAt: Date.now(),
        confirmedEmail: false,
        email: email,
        billingInfo: {},
        businessInfo: {},
      });

      await this.emailLogin(email, password, false);
    } catch (error) {
      return error;
    }
  }

  async emailForgotPassword(email: string) {
    try {
      await auth.sendPasswordResetEmail(email);
    } catch (error) {
      return error;
    }
  }

  async resetPassword(actionCode: string, newPassword: string) {
    try {
      const verifyPasswordResetCodeResult = await auth.verifyPasswordResetCode(actionCode);

      if (verifyPasswordResetCodeResult) {
        await auth.confirmPasswordReset(actionCode, newPassword);
      }
    } catch (error) {
      return error;
    }
  }

  async oauthRedirectResult() {
    auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);

    try {
      const result = await auth.getRedirectResult();

      console.log(result);
    } catch (error) {
      // TODO
      console.error(error);
    }
  }

  googleLogin() {
    const provider = new firebase.auth.GoogleAuthProvider();

    provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    auth.signInWithRedirect(provider);
  }

  logout(): void {
    this.setUser(undefined);
    this.setLoading(false);
    this.resetCurrentPathname();
    auth.signOut();
  }

  isLoggedIn(): boolean {
    return !!auth.currentUser;
  }

  subscribe(callback: (user: UserModel | undefined, loading: boolean) => void): void {
    this._subscribers.push(callback);
  }

  unsubscribe(callback: (user: UserModel | undefined, loading: boolean) => void): void {
    this._subscribers = this._subscribers.filter(cb => cb !== callback);
  }

  notifyAll(): void {
    const user = this.getUser();
    const loading = this.getLoading();

    for (let i = 0; i < this._subscribers.length; i++) {
      this._subscribers[i](user, loading);
    }
  }
}

const AuthService = AuthServiceClass.getInstance();

export default AuthService;
