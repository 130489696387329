import { FunctionComponent } from 'react';
import CreditSummaryCard from '../../components/CreditSummaryCard/CreditSummaryCard';
import ShipmentCard from '../../components/Widgets/ShipmentCard';
import AuthService from '../../services/auth-service';

const Dashboard: FunctionComponent = (): JSX.Element => {
  const currentUser = AuthService.getUser();

  return (
    <div>
      <CreditSummaryCard available={currentUser?.balance || 0} pending={0} />
      <ShipmentCard latest />
    </div>
  );
};

export default Dashboard;
