import { FunctionComponent } from 'react';
import './BigStat.scss';

type BigStatProps = {
  width?: number;
  value: string | number;
  label: string;
};

const BigStat: FunctionComponent<BigStatProps> = ({
  width,
  value,
  label,
}): JSX.Element => {
  const conditionalProps = !width ? {} : {
    style: {
      minWidth: `${width}%`,
    },
  };

  return (
    <div className='big-stat' {...conditionalProps}>
      <div className='big-stat__value'>{value}</div>
      <div className='big-stat__label'>{label}</div>
    </div>
  );
};

export default BigStat;
