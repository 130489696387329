import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { MultiStepFormComponentProps } from '../../components/MultiStepForm/MultiStepFormContent';
import { FieldType, FormGroupFieldMap } from '../../lib/Form/fieldsArray';
import { DropdownOption } from '../../lib/Form/Dropdown';
import { FEDEX, USPS, UPS } from '../../services/helper';
import { RadioGroupOption } from '../../lib/Form/RadioGroupOption';
import Select, { ValueType } from 'react-select';
import FormField from '../../components/Form/FormField/FormField';
import Banner from '../../components/Banner/Banner';
import Summary from './Summary';
import cx from 'classnames';

const ALL_CARRIERS = 'allCarriers';
const LOWEST = 'lowest';
const EARLIEST = 'earliest';
const ALL_RESULTS = 'allResults';
const BEST_RESULTS = 'bestResults';
const RATE_ID = 'rateId';

const CARRIER_OPTIONS: DropdownOption[] = [
  { value: ALL_CARRIERS, label: 'All' },
  { value: FEDEX, label: FEDEX },
  { value: USPS, label: USPS },
  { value: UPS, label: UPS },
];

const SORT_BY_OPTIONS: DropdownOption[] = [
  { value: LOWEST, label: 'Lowest Cost' },
  { value: EARLIEST, label: 'Earliest Delivery' },
];

const SHOW_OPTIONS: DropdownOption[] = [
  { value: BEST_RESULTS, label: 'Top 3 Results' },
  { value: ALL_RESULTS, label: 'All Results' },
]

const EstimateLabel: FunctionComponent<MultiStepFormComponentProps> = ({
  values,
  formGroupFieldMap,
  ...contentProps
}): JSX.Element => {
  const rateFieldKey = formGroupFieldMap &&
    Object.keys(formGroupFieldMap).find((key: string) => formGroupFieldMap[Number(key)].filter((c: FieldType) => c.name === RATE_ID).length > -1);
  const [formFields, setFormFields] = useState<FormGroupFieldMap | null>();
  const [radioOptions, setRadioOptions] = useState<RadioGroupOption[]>([]);
  const [selectedCarrier, setSelectedCarrier] = useState(CARRIER_OPTIONS[0]);
  const [selectedSortBy, setSelectedSortBy] = useState(SORT_BY_OPTIONS[0]);
  const [selectedShow, setSelectedShow] = useState(SHOW_OPTIONS[0]);

  useEffect(() => {
    if (formGroupFieldMap && rateFieldKey) {
      const options = formGroupFieldMap[Number(rateFieldKey)].filter(f => f.name === RATE_ID);
      const newFormGroupFields = { ...formGroupFieldMap };

      if (options?.length > 0 && options[0].options) {
        setRadioOptions(options[0].options);
        if (newFormGroupFields[Number(rateFieldKey)]) {
          const result = getFilteredResults(options[0].options);

          newFormGroupFields[Number(rateFieldKey)].filter(f => f.name === RATE_ID)[0].options = [...result];
          setFormFields(newFormGroupFields);
        }
      }
    }
  }, [formGroupFieldMap, rateFieldKey]);

  useEffect(() => {
    const newFormGroupFields = { ...formFields };

    if (newFormGroupFields[Number(rateFieldKey)]) {
      const result = getFilteredResults();

      newFormGroupFields[Number(rateFieldKey)].filter(f => f.name === RATE_ID)[0].options = [...result];
      setFormFields(newFormGroupFields);
    }
  }, [selectedCarrier, selectedSortBy, selectedShow, radioOptions, rateFieldKey]);

  const getFilteredResults = (options?: RadioGroupOption[]): RadioGroupOption[] => {
    let result = [...(options ? options : radioOptions)];

    if (selectedCarrier.value !== ALL_CARRIERS) {
      result = result.filter(c => c.type === selectedCarrier.value);
    }
    if (selectedSortBy.value === EARLIEST) {
      result = result.sort((a, b) => (new Date(a?.date || '') > new Date(b?.date || '')) ? 1 : -1);
    }

    if (selectedSortBy.value === LOWEST) {
      result = result.sort((a, b) => ((a?.amount || 0) > (b?.amount || 0)) ? 1 : -1);
    }

    if (selectedShow.value === BEST_RESULTS) {
      result = result.splice(0, 3);
    }

    return result;
  }

  const handleCarrierOnChange = (option: ValueType<DropdownOption, boolean>) => {
    const selectedOption = option as DropdownOption;

    setSelectedCarrier(selectedOption);
  };

  const handleSortByOnChange = (option: ValueType<DropdownOption, boolean>) => {
    const selectedOption = option as DropdownOption;

    setSelectedSortBy(selectedOption);
  };

  const handleShowOnChange = (option: ValueType<DropdownOption, boolean>) => {
    const selectedOption = option as DropdownOption;

    setSelectedShow(selectedOption);
  };

  const renderFields = <>
    {
      formFields && Object.keys(formFields).map((key, index): ReactNode => {
        return (
          <div
            className={cx('multi-step-form__group', {
              'multi-step-form__group__radio': formFields[Number(key)].findIndex((f: FieldType) => f.fieldType === 'radio') > -1,
            })}
            key={`multi-step-form-group-${index}`}
          >
            {
              formFields[Number(key)].map((field: FieldType, i: number): ReactNode => {
                return (
                  <FormField
                    key={`multi-step-form-content-${i}`}
                    {...contentProps}
                    {...field}
                  />
                )
              })
            }
          </div>
        )
      })
    }
  </>


  const renderDisclaimers = () => {
    const selectedValue = values?.rateId && radioOptions.filter(option => option.value === values.rateId)[0];
    const { label, type } = selectedValue || {};

    if (type === 'USPS') {
      if (label === 'First Class') {
        return <Banner type='alert' text='First Class: Package must be less than 13 oz.' />
      }

      if (label === 'Media Mail') {
        return <Banner type='alert' text='Media Mail: Contents must be books, or video and sound recordings.' />
      }

      if (label === 'Priority Mail Flat Rate') {
        return <Banner type='alert' text='Priority Mail: Must use USPS Priority Mail Flat Rate Packaging.' />
      }

      if (label === 'Priority Mail Express Flat Rate') {
        return <Banner type='alert' text='Priority Mail Express: Must use USPS Priority Mail Express Flat Rate Packaging.' />
      }
    }

    return null;
  };

  return (
    <>
      <div className='multi-step-form__summary-title'>Order Details</div>
      <div className='multi-step-form__filter mt-2'>
        <div className='multi-step-form__filter__group'>
          <div className='multi-step-form__filter__label'>
            Carrier
          </div>
          <Select
            classNamePrefix='filter'
            defaultValue={CARRIER_OPTIONS[0]}
            value={selectedCarrier}
            onChange={handleCarrierOnChange}
            options={CARRIER_OPTIONS}
            placeholder={'Carrier'}
            isSearchable={false}
          />
        </div>
        <div className='multi-step-form__filter__group'>
          <div className='multi-step-form__filter__label'>
            Sort By
          </div>
          <Select
            classNamePrefix='filter'
            value={selectedSortBy}
            defaultValue={SORT_BY_OPTIONS[0]}
            onChange={handleSortByOnChange}
            options={SORT_BY_OPTIONS}
            placeholder={'Sort By'}
            isSearchable={false}
          />
        </div>
        <div className='multi-step-form__filter__group'>
          <div className='multi-step-form__filter__label'>
            Show
          </div>
          <Select
            classNamePrefix='filter'
            value={selectedShow}
            defaultValue={SHOW_OPTIONS[0]}
            onChange={handleShowOnChange}
            options={SHOW_OPTIONS}
            placeholder={'Show'}
            isSearchable={false}
          />
        </div>
      </div>
      {renderFields}
      {renderDisclaimers()}
      <div className='multi-step-form__summary-title'>Shipment Details</div>
      {values && <Summary values={values} />}
    </>
  );
};

export default EstimateLabel;
