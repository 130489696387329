import { useRef } from 'react';
import cx from 'classnames';
import { LogOut, Settings, User } from 'react-feather';
import { Link } from 'react-router-dom';
import { useDetectOutsideClick } from '../../lib/hooks/hooks';
import AuthService from '../../services/auth-service';
import Button from '../Button/Button';
import './ProfileDropdown.scss';

const ProfileDropdown = (): JSX.Element => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const logout = () => {
    setIsActive(false);
    AuthService.logout();
  };

  return (
    <div className={cx('profile-dropdown', 'dropdown', { 'profile-dropdown--active': isActive })}>
      <div className='dropdown-menu-container'>
        <button onClick={() => setIsActive(!isActive)} className='dropdown-menu__btn'>
          <User size={30} />
        </button>
        <div
          ref={dropdownRef}
          className={cx(
            'dropdown-menu',
            {
              'dropdown-menu--active': isActive,
            },
          )}
        >
          <ul>
            {/* Temporarily remove */}
            {/* <li>
              <Link to='/account' onClick={() => setIsActive(false)}>
                <User className='dropdown-menu__icon' />
                <span>Account</span>
              </Link>
            </li> */}
            <li>
              <Link to='/settings' onClick={() => setIsActive(false)}>
                <Settings className='dropdown-menu__icon' />
                <span>Settings</span>
              </Link>
            </li>
            <li>
              <Button type='secondary' onClick={logout}>
                <LogOut className='dropdown-menu__icon' />
                <span>Log Out</span>
              </Button>
            </li>
          </ul>
      </div>
    </div>
    </div >
  );
};

export default ProfileDropdown;