import { Field, FieldProps, FormikErrors, FormikTouched, FormikValues } from 'formik';
import cx from 'classnames'
import { ComponentType } from 'react';
import { IconProps } from 'react-feather';
import { RadioGroupOption } from '../../../lib/Form/RadioGroupOption';
import './Radio.scss';

type RadioProps = {
  label: string;
  name: string;
  errors?: FormikErrors<FormikValues>;
  touched?: FormikTouched<FormikValues>;
  options: Array<RadioGroupOption>;
};

interface RadioButtonTypes extends FieldProps {
  id: string;
  label: string;
  index: number;
  value: string | number;
  option: RadioGroupOption,
}

const RadioButton = ({
  field: { name, onChange, onBlur },
  form,
  id,
  index,
  option,
  ...props
}: RadioButtonTypes) => {
  const renderOptionIcon = (iconComponent: ComponentType<IconProps>): JSX.Element => {
    const Icon = iconComponent;

    return (
      <Icon />
    );
  }

  return (
    <div className='radio-group'>
      <input
        name={name}
        id={id}
        type='radio'
        checked={props.value === form.values[name]}
        onChange={onChange}
        onBlur={onBlur}
        className={cx('radio__option__input')}
        {...props}
      />
      <label
        key={`radio__option__label-${index}`}
        className={cx(
          'radio__option__label',
          {
            'radio__option__label--selected': props.value === form.values[name],
          },
        )}
        htmlFor={id}
      >
        {option.image &&
          <img className='radio__option__image' src={option.image} />
        }
        {
          option.iconComponent && renderOptionIcon(option.iconComponent)
        }
        {
          option?.pillComponent && option.pillComponent
        }
        <div>
          {
            option?.svgLabel ?
              <div className='radio__option__svg-label'>
                <img src={option.svgLabel} />
                <div className='radio__option__text-label'>{option.label}</div>
              </div> : <p><strong>{option.label}</strong></p>
          }
          {option?.subLabel && <p>{option.subLabel}</p>}
        </div>
      </label>
    </div>
  );
};

const Radio = ({ name, label, options, errors, touched }: RadioProps): JSX.Element => {
  const hasErrors = errors && touched && errors[name] && touched[name];

  return (
    <div>
      <div className='radio'>
        <div className='radio__label'>{label}</div>
        {
          options.map((option, index) => (
            <Field
              key={`radio__option-${index}`}
              id={`radio__option-${index}`}
              component={RadioButton}
              index={index}
              name={name}
              option={option}
              value={option.value} />
          ))
        }
      </div>
      {hasErrors && (
        <div className='mt-1 form-errors'>{errors && errors[name]}</div>
      )}
    </div>
  );
};

export default Radio;
