import { DropdownOption } from '../lib/Form/Dropdown';

export const provincesOptions: DropdownOption[] = [
  {
    value: 'AB',
    label: 'Alberta',
    type: 'CA',
  },
  {
    value: 'BC',
    label: 'British Columbia',
    type: 'CA',
  },
  {
    value: 'MB',
    label: 'Manitoba',
    type: 'CA',
  },
  {
    value: 'NB',
    label: 'New Brunswick',
    type: 'CA',
  },
  {
    value: 'NL',
    label: 'Newfoundland and Labrador',
    type: 'CA',
  },
  {
    value: 'NS',
    label: 'Nova Scotia',
    type: 'CA',
  },
  {
    value: 'ON',
    label: 'Ontario',
    type: 'CA',
  },
  {
    value: 'PE',
    label: 'Prince Edward Island',
    type: 'CA',
  },
  {
    value: 'QC',
    label: 'Quebec',
    type: 'CA',
  },
  {
    value: 'SK',
    label: 'Saskatchewan',
    type: 'CA',
  },
  {
    value: 'NT',
    label: 'Northwest Territories',
    type: 'CA',
  },
  {
    value: 'YU',
    label: 'Yukon',
    type: 'CA',
  },
]