import AuthPage from './AuthPage';
import AuthService from '../../services/auth-service';
import Card from '../../components/Card/Card';
import { Link } from 'react-router-dom';
import { Form, Formik, FormikHelpers, FormikValues } from 'formik';
import Button from '../../components/Button/Button';
import TextField from '../../components/Form/TextField/TextField';
import { forgotPasswordFormValidationSchema } from '../../lib/Form/forgotPasswordFormValidationSchema';
import { useState } from 'react';
const initialValues = {
  email: '',
};

const ForgotPassword = (): JSX.Element => {
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSubmit = async (values: FormikValues, actions: FormikHelpers<typeof initialValues>) => {
    actions.setSubmitting(false);
    await AuthService.emailForgotPassword(values.email);
    setFormSubmitted(true);
  }

  return (
    <AuthPage>
      <Card title='Forgot Password'>
        {
          formSubmitted
            ? <div>If the account exists, an email has been sent  with instructions on how to reset the password.</div>
            :
            <Formik
              initialValues={initialValues}
              validationSchema={forgotPasswordFormValidationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, errors, touched }) => (
                <Form>
                  <TextField
                    name='email'
                    label='Email'
                    placeholder='Enter email'
                    type='email'
                    errors={errors}
                    touched={touched}
                  />
                  <Button type='submit' disabled={isSubmitting}>
                    Reset Password
                  </Button>
                </Form>
              )}
            </Formik>
        }
        {!formSubmitted && (
        <>
          <div className='mt-2'>
            Already have an account?{' '}
            <Link to='/login' className='text-link'>
              Sign in
            </Link>
          </div>
          <div className='mt-2'>
            No Account?{' '}
            <Link to='/signup' className='text-link'>
              Create one
            </Link>
          </div>
          </>
        )}
        {formSubmitted && (
          <div className='mt-2'>
            <Link to='/login' className='text-link'>
              Return to login
            </Link>
          </div>
        )}
      </Card>
    </AuthPage>
  );
};

export default ForgotPassword;
