import { Field, FormikErrors, FormikHelpers, FormikTouched, FormikValues } from 'formik';
import cx from 'classnames';
import './TextField.scss';
import { Clipboard, Info } from 'react-feather';

type TextFieldProps = {
  placeholder: string;
  name: string;
  label: string;
  errors?: FormikErrors<FormikValues>;
  touched?: FormikTouched<FormikValues>;
  values?: FormikValues;
  customValidation?: string;
  optional?: boolean;
  allowPasting?: boolean;
  handleOnPasteFunc?: (event: ClipboardEvent, setFieldValue: FormikHelpers<FormikValues>) => Promise<void>;
  setFieldValue?: FormikHelpers<FormikValues>;
  type: 'email' | 'password' | 'text' | 'number';
};

const TextField = (props: TextFieldProps): JSX.Element => {
  const {
    placeholder,
    type,
    name,
    label,
    errors = {},
    touched = {},
    customValidation = '',
    optional = false,
    values = {},
    allowPasting = false,
    handleOnPasteFunc,
    setFieldValue,
  } = props;
  let err = (errors[name] || errors[customValidation]);

  if (optional && values[name] === '') {
    err = errors[name];
  }
  const hasError = errors && touched && err && touched[name];

  return (
    <div className='text-field'>
      <label
        className='text-field__label'
        htmlFor={name}
      >
        {label}
        {!optional && <span className='text-field__required'>*</span>}
      </label>
      <Field
        className={cx('text-field__input', { 'text-field__input--with-errors': hasError })}
        id={name}
        name={name}
        type={type}
        {...(type === 'number' && {
          min: '0.00',
          max: '10000.00',
          step: '0.01',
        })}
        placeholder={placeholder}
        {
        ...(allowPasting && handleOnPasteFunc && setFieldValue && {
          onPaste: (event: ClipboardEvent) => handleOnPasteFunc(event, setFieldValue),
        })
        }
      />
      {
        allowPasting && (
          <div className='text-field__helper-text'>
            <Clipboard size={15} />
            Tip: You can paste the full contact details in this field and it will auto fill the form for you.
          </div>
        )
      }
      {
        name === 'shipFrom' && (
          <div className='text-field__helper-text'>
            <Info size={15} />
            Tip: If you own multiple store fronts then use this field to inform the user which storefront this is from.
          </div>
        )
      }
      {hasError && (
        <div className='form-errors'>{errors && (errors[name] || errors[customValidation])}</div>
      )}
    </div>
  );
};

export default TextField;
