import Modal from 'react-modal';
import ShipmentService from '../../services/shipment-service';
import BatchService from '../../services/batch-service';
import Button from '../Button/Button';
import Card from '../Card/Card';
import './CreateSetModal.scss';
import { useHistory } from 'react-router';
import Banner from '../Banner/Banner';
import { useState } from 'react';

// Apply once for accessibility needs
const root = document.getElementById('root');

if (root) {
  Modal.setAppElement(root);
}

const CreateSetModal = (): JSX.Element => {
  const [error, setError] = useState('');
  const selectedIds = ShipmentService.getCurrentSelectedIds();
  const currentHistory = useHistory();

  const handleConfirm = async () => {
    try {
      await BatchService.createBatch(selectedIds);

      ShipmentService.closeModal();
      currentHistory.push('/shipment/success');
      currentHistory.goBack();
    } catch (error) {
      setError(error?.message || 'Unable to create batch.');
    }
  }

  return (
    <Modal
      isOpen
      className='create-set-modal'
      overlayClassName='create-set-modal-overlay'
      onRequestClose={() => ShipmentService.closeModal()}
    >
      <div>
        <Card title='Create Batch'>
          {error && <Banner type='error' text={error} />}
          <p>
            You have selected <strong>{selectedIds.length} shipments</strong> to batch.
            <br />
            <br />
            Once batched, you will not be able to automatically cancel
            your labels and will need to contact Breeze Courier for any changes.
          </p>
          <div className='button-group'>
            <Button type='secondary' onClick={() =>
              ShipmentService.closeModal()
            }>Cancel</Button>
            {!error && <Button type='primary' onClick={handleConfirm}>Confirm</Button>}
          </div>
        </Card>
      </div>
    </Modal>
  );
};

export default CreateSetModal;
