import { Field, FieldProps, FormikErrors, FormikTouched, FormikValues } from 'formik';
import { ReactNode } from 'react';
import Select, { OptionsType, ValueType } from 'react-select';
import cx from 'classnames';
import { DropdownOption } from '../../../lib/Form/Dropdown';
import './Dropdown.scss';

type DropdownProps = {
  options: OptionsType<DropdownOption>;
  isMulti?: boolean;
  label: string;
  placeholder: string;
  errors?: FormikErrors<FormikValues>;
  touched?: FormikTouched<FormikValues>;
  customValidation?: string;
  name: string;
}

interface FormikSelectProps extends FieldProps {
  options: OptionsType<DropdownOption>;
  isMulti?: boolean;
  placeholder: string;
  className: string;
}

export const FormikSelect: ReactNode = ({
  field,
  form,
  options,
  isMulti = false,
  placeholder,
  className,
}: FormikSelectProps) => {
  const getSelectOptions = () => {
    if (field.name === 'state') {
      return options.filter(o => o.type === (form.values.military ? 'military' : form.values.country));
    }
    return options;
  }
  const selectOptions = getSelectOptions();
  const onChange = (option: ValueType<DropdownOption | DropdownOption[], boolean>) => {
    form.setFieldValue(
      field.name,
      isMulti
        ? (option as DropdownOption[]).map((item: DropdownOption) => item.value)
        : (option as DropdownOption).value,
    );
  };

  const getValue = (): DropdownOption | readonly DropdownOption[] | null | undefined => {
    return isMulti
      ? options.filter(option => field.value.indexOf(option.value) >= 0)
      : options.find(option => option.value === field.value);
  };

  return (
    <Select
      name={field.name}
      className={className}
      classNamePrefix='dropdown'
      value={getValue()}
      onChange={onChange}
      onBlur={form.handleBlur}
      options={selectOptions}
      isMulti={isMulti}
      placeholder={placeholder}
    />)
};

const Dropdown = ({
  options,
  isMulti = false,
  label,
  placeholder,
  name,
  errors,
  touched,
  customValidation = '',
}: DropdownProps): JSX.Element => {
  const hasErrors = errors && touched && (errors[name] || errors[customValidation]) && touched[name];

  return (
    <div className='dropdown'>
      <label
        className='dropdown__label'
        htmlFor={name}
      >
        {label}
        <span className='dropdown__required'>*</span>
      </label>
      <Field
        isMulti={isMulti}
        component={FormikSelect}
        className={cx({ 'dropdown--with-errors': hasErrors })}
        name={name}
        options={options}
        placeholder={placeholder}
      />
      {hasErrors && (
        <div className='form-errors'>{errors && (errors[name] || errors[customValidation])}</div>
      )}
    </div>
  )
};

export default Dropdown;