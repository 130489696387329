import Modal from 'react-modal';
import { useState } from 'react';
import { useHistory } from 'react-router';
import ModalService from '../../services/modal-service';
import ShipmentService from '../../services/shipment-service';
import Button from '../Button/Button';
import Card from '../Card/Card';
import './CancelLabelModal.scss';
import Banner from '../Banner/Banner';
import Loader from '../Loader/Loader';

// Apply once for accessibility needs
const root = document.getElementById('root');

if (root) {
  Modal.setAppElement(root);
}

const CancelLabelModal = (): JSX.Element => {
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const currentShipment = ShipmentService.getCurrentShipment();
  const currentHistory = useHistory();
  const allowCancellation = currentShipment?.status === 'READY' || currentShipment?.status === 'READYTOPRINT';
  const handleSubmit = async () => {
    setIsSubmitting(true);
    const result = await ShipmentService.voidLabel();

    if (result) {
      setIsSubmitting(false);
      setError(result);
    } else {
      ModalService.closeModal();
      currentHistory.push('/cancelLabel/success');
      currentHistory.goBack();
    }
  }

  const renderError = error && <Banner type='error' text={error}/>

  return (
    <Modal
      isOpen
      className='cancel-label-modal'
      overlayClassName='cancel-label-modal-overlay'
      onRequestClose={() => ShipmentService.closeModal()}
    >
      <div>
        <Card title='Cancel Label'>
          { renderError }
          {
            allowCancellation ?
              `Confirm you wish to cancel this shipment.
               Its label will be voided and all costs will be refunded to your account.
                To re-ship this item you will need to create a new shipment.` :
              `This shipment is already due for delivery.
               Please contact Breeze Courier directly to see what options are available for you.`
          }

          <div className='button-group'>
            <Button type='secondary' onClick={() => ShipmentService.closeModal()}>
              {allowCancellation ? 'Cancel' : 'Close'}
            </Button>
            {allowCancellation && <Button type='primary' onClick={handleSubmit}>
              {
                isSubmitting ? <Loader /> : 'Confirm'
              }
            </Button>}
          </div>
        </Card>
      </div>
    </Modal>
  );
};

export default CancelLabelModal;
