import { useState } from 'react';
import { Loader } from 'react-feather';
import printJS from 'print-js';
import Modal from 'react-modal';
import ShipmentService from '../../services/shipment-service';
import Button from '../Button/Button';
import Card from '../Card/Card';
import './LabelPrintModal.scss';

// Apply once for accessibility needs
const root = document.getElementById('root');

if (root) {
  Modal.setAppElement(root);
}

const LabelPrintModal = (): JSX.Element => {
  const [loading, setLoading] = useState(false);

  const handlePrint = async () => {
    setLoading(true);
    const url = ShipmentService.getCurrentLabelUrl()?.replace('.pdf', '.png');

    if (url) {
      printJS({
        printable: url,
        type: 'image',
        imageStyle: 'display:block; margin: 0 auto; width: 80%;',
      });
    }
    await ShipmentService.updateShipmentLabelViewed();
    setLoading(false);
    ShipmentService.closeModal();
  }

  return (
    <Modal
      isOpen
      className='label-print-modal'
      overlayClassName='label-print-modal-overlay'
      onRequestClose={() => ShipmentService.closeModal()}
    >
      <div>
        <Card title='Print Shipping Label'>
          <p>
            Warning, you only have a single chance to print the shipping label and must
            use this shipping label only for the package you are shipping. Re-using the
            label is prohibited and will be detected.
          </p>
          <div className='button-group'>
            <Button type='secondary' onClick={() => ShipmentService.closeModal()}>Cancel</Button>
            <Button type='primary' onClick={handlePrint} disabled={loading}>
              {loading ? <Loader /> : `I'm Ready To Print`}
            </Button>
          </div>
        </Card>
      </div>
    </Modal>
  );
};

export default LabelPrintModal;
