import { Loader as Spinner } from 'react-feather';
import './Loader.scss';

const Loader = (): JSX.Element => {
  return (
    <div className='loader'>
      <Spinner size={32} />
    </div>
  );
};

export default Loader;
