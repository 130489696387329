import Card from '../Card/Card';
import _ from 'lodash';
import { shipmentsColumnGenerator, RowData } from '../../lib/table/summaryCardCols';
import { useEffect, useState, useCallback } from 'react';
import ShipmentService from '../../services/shipment-service';
import { default as PaginationTable } from '../MaterialTable/PaginationTable';
import AuthService from '../../services/auth-service';
import { DEBOUNCE_MS, PAGE_SIZE } from '../MaterialTable/tableHelper';
import Loader from '../Loader/Loader';
import { statusToLabel } from '../../services/helper';

const CancelledShipmentCard = (): JSX.Element => {
  const [data, setData] = useState<RowData[]>();
  const [debouncedSearchText, setDebouncedSearchText] = useState('');
  const [pagination, setPagination] = useState<{ page: number, pageSize: number }>({ page: 0, pageSize: PAGE_SIZE });
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getRowData = async () => {
    setIsLoading(true);
    let rowData = [];

    if (debouncedSearchText !== '') {
      rowData = await ShipmentService.searchAllCancelledShipments(debouncedSearchText);
    } else {
      rowData = await ShipmentService.getAllCancelledShipments(pagination.page, pagination.pageSize);
    }
    if (rowData) {
      const newData = rowData.map<RowData>((row: RowData) => {
        return {
          ...row,
          orderNumber: row.id || 'N/A',
          shipmentId: row.shipmentId || 'N/A',
          trackingNumber: row.trackingNumber || 'N/A',
          customer: row.shipTo.name,
          label: statusToLabel(row.status),
          destination: row.shipTo.city + ', ' + row.shipTo.state,
        }
      });

      setData(newData);
      setIsLoading(false);
    } else {
      setData([]);
    }
  };

  useEffect(() => {
    getRowData();
  }, [pagination, debouncedSearchText]);

  useEffect(() => {
    return () => {
      ShipmentService.setLastVisibleShipment(null);
    }
  }, []);

  const fetchDataByPage = (page: number, pageSize: number) => {
    setPagination({ page, pageSize });
  }

  const onSearchChange = (searchText: string) => {
    debounce(searchText);
  }

  const debounce = useCallback(
    _.debounce((_searchText: string) => {
      setDebouncedSearchText(_searchText);
    }, DEBOUNCE_MS),
    [],
  );

  const pageSize = debouncedSearchText !== '' ? 10 : pagination.pageSize;
  const page = debouncedSearchText !== '' ? 0 : pagination.page;

  const getTotalCount = () => {
    if (debouncedSearchText) {
      return data?.length || 0
    }
    return AuthService.getUser()?.totalCancelledShipments || 0;
  }

  return (
    <Card
      title={`Cancelled Shipments`}
    >
      {!data ? <Loader /> : (
        <PaginationTable
          rowData={data}
          isLoading={isLoading}
          totalCount={getTotalCount()}
          columnDefinition={shipmentsColumnGenerator('cancelled')}
          page={page}
          pageSize={Math.min(pageSize, data.length + 1)}
          fetchDataByPage={fetchDataByPage}
          pagination={pageSize === PAGE_SIZE}
          onSearchChange={onSearchChange}
        />
      )}
    </Card>
  );
};

export default CancelledShipmentCard;
