import * as Yup from 'yup';

export const resetPasswordFormValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
    .min(6),
  passwordConfirm: Yup.string()
    .required('Password is required')
    .min(6)
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});