import React, { ReactNode, FunctionComponent } from 'react';
import cx from 'classnames';
import './CardRow.scss';

type CardRowProps = {
  cards: ReactNode[];
  direction?: 'row' | 'column'
};

const CardRow: FunctionComponent<CardRowProps> = ({
  cards,
  direction = 'row',
}): JSX.Element => {

  return (
    <div className={cx(
      'card-row',{
        'card-row--column': direction === 'column',
      },
    )}>
      {cards && cards.map((card, index) => {
        const isLast = index === cards.length - 1;

        return (
          <React.Fragment key={`card-row-column-${index}`}>
            {card}
            {!isLast && <div className='card-row__spacer' />}
          </React.Fragment>
        )
      })}
    </div>
  );
};

export default CardRow;
