import { Field, FieldProps, FormikErrors, FormikTouched, FormikValues } from 'formik';
import cx from 'classnames';
import { ReactNode } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DateField.scss';

type DateFieldProps = {
  placeholder: string;
  name: string;
  label: string;
  errors?: FormikErrors<FormikValues>,
  touched?: FormikTouched<FormikValues>,
};

export const FormikDatePicker: ReactNode = ({
  field,
  form,
}: FieldProps) => {
  const onChange = (value: Date) => {
    form.setFieldValue(
      field.name,
      value,
    );
  };

  const isMonWedFri = (date: Date) => {
    const day = date.getDay();

    return day === 1 || day === 3 || day === 5;
  };

  return (
    <DatePicker
      selected={field.value === '' ? new Date() : new Date(field.value)}
      dateFormat='MMMM d, yyyy'
      className='date-field__input'
      minDate={new Date()}
      name={field.name}
      onChange={onChange}
      filterDate={isMonWedFri}
    />
  )
}

const DateField = (props: DateFieldProps): JSX.Element => {
  const { placeholder, name, label, errors, touched } = props;
  const hasErrors = errors && touched && errors[name] && touched[name];

  return (
    <div className='date-field'>
      <label
        className='date__label'
        htmlFor={name}
      >
        {label}
      </label>
      <Field
        className={cx({ 'date__input--with-errors': hasErrors })}
        id={name}
        name={name}
        component={FormikDatePicker}
        placeholder={placeholder}
      />
      {hasErrors && (
        <div className='form-errors'>{errors && errors[name]}</div>
      )}
    </div>
  );
};

export default DateField;
