import logo from '../../assets/logo-dark.png';
import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import ProfileDropdown from '../ProfileDropdown/ProfileDropdown';
import Button from '../Button/Button';
import './Header.scss';
import AuthService from '../../services/auth-service';
import PaymentService from '../../services/payment-service';
import { getDecimal } from '../../services/helper';

type Breadcrumb = {
  title: string;
  path: string;
};

type HeaderProps = {
  breadCrumbs: Breadcrumb[];
};

const Header: FunctionComponent<HeaderProps> = ({
  breadCrumbs,
}): JSX.Element => {
  const isCreateAShipment = breadCrumbs && breadCrumbs[breadCrumbs.length - 1].path === '/shipment/create';
  const currentUser = AuthService.getUser();

  return (
    <header className='header'>
      <div className='header__logo-wrapper'>
        <img src={logo} alt='Breeze Courier Logo' />
      </div>
      <div className='header__content'>
        <ul className='header__breadcrumbs'>
          {breadCrumbs.map(({ title, path }, index) => {
            const key = `breadcrumb-${index}`;

            if (index === breadCrumbs.length - 1) {
              return (
                <li key={key} className='header__crumb header__crumb--last'>
                  {title}
                </li>
              );
            }

            return (
              <li key={key} className='header__crumb header__crumb--link'>
                <Link to={path}>{title}</Link>
                <span className='header__crumb-spacer'>/</span>
              </li>
            );
          })}
        </ul>
        <div className='header__actions'>
          <div className='header__actions__buttons'>
            <div className='mr-2 text-button'>
              <Button type='primary' onClick={() => PaymentService.openModal('', null)}>
                ${getDecimal(currentUser?.balance || 0)} CAD
              </Button>
            </div>
            {!isCreateAShipment && <div className='mr-4 header__tablet-hidden'><Button type='primary' to='/shipment/create'>Create A Shipment</Button></div>}
          </div>
          <ProfileDropdown />
        </div>
      </div>
    </header >
  );
};

export default Header;
