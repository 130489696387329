import logo from '../../assets/logo-dark.png';
import { FunctionComponent } from 'react';
import { navBarRoutes } from '../../lib/routes/routes';
import { Link } from 'react-router-dom';
import { Home } from 'react-feather';
import cx from 'classnames';
import './Navbar.scss';

type NavbarProps = {
  activeRoute: string;
};

const Navbar: FunctionComponent<NavbarProps> = ({
  activeRoute,
}): JSX.Element => {
  return (
    <nav className='navbar'>
      <div className='navbar__logo-wrapper'>
        <img src={logo} alt='Breeze Courier Logo' />
      </div>
      <ul className='navbar__content'>
        {navBarRoutes.map((navLink, index) => {
          const { title, path, icon } = navLink;
          const PathIcon = icon || Home;

          return (
            <li
              key={`navbar-link-${title}-${index}`}
              className={cx('navbar__link', {
                'navbar__link--active': title === activeRoute,
              })}
            >
              <Link to={path}>
                <PathIcon className='navbar__link-icon' />
                <span className='navbar__link-text'>{title}</span>
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Navbar;
