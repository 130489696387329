import AuthPage from './AuthPage';
import AuthService from '../../services/auth-service';
import Card from '../../components/Card/Card';
import { Form, Formik, FormikHelpers, FormikValues } from 'formik';
import Button from '../../components/Button/Button';
import TextField from '../../components/Form/TextField/TextField';
import { resetPasswordFormValidationSchema } from '../../lib/Form/resetPasswordFormValiationSchema';
import { Redirect, RouteProps, useLocation } from 'react-router-dom';
import { FunctionComponent, useState } from 'react';
import Banner from '../../components/Banner/Banner';
const initialValues = {
  password: '',
  passwordConfirm: '',
};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const ResetPassword: FunctionComponent<RouteProps> = (): JSX.Element => {
  const [passwordResetSuccessful, setPasswordResetSuccessful] = useState(false);
  const [formError, setFormError] = useState('');

  const query = useQuery();

  const handleSubmit = async (values: FormikValues, actions: FormikHelpers<typeof initialValues>) => {
    actions.setSubmitting(false);
    if (query.get('mode') !== 'resetPassword') {
      setFormError('The link is invalid');
    } else {
      // TODO - use correct prop type instead of any
      const result: any = await AuthService.resetPassword(query.get('oobCode') || '', values.passwordConfirm);

      if (!result) {
        setPasswordResetSuccessful(!result);
      } else {
        setFormError(result?.message || 'Something went wrong, please try again later.');
      }
    }
  }

  const renderFormError = formError && <Banner type='error' text={formError} />


  return (
    <AuthPage>
      {
        passwordResetSuccessful &&
        <Redirect
          to={{
            pathname: '/login',
            state: {
              passwordReset: passwordResetSuccessful,
            },
          }}
        />
      }
      <Card title='Reset Password'>
        {renderFormError}
        <Formik
          initialValues={initialValues}
          validationSchema={resetPasswordFormValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, errors, touched }) => (
            <Form>
              <TextField
                name='password'
                label='Password'
                placeholder='Enter password'
                type='password'
                errors={errors}
                touched={touched}
              />
              <TextField
                name='passwordConfirm'
                label='Password Confirm'
                placeholder='Confirm password'
                type='password'
                errors={errors}
                touched={touched}
              />
              <Button type='submit' disabled={isSubmitting}>
                Reset Password
              </Button>
            </Form>
          )}
        </Formik>
      </Card>
    </AuthPage>
  );
};

export default ResetPassword;
