import { Form, Formik, FormikHelpers, FormikValues } from 'formik';
import { ReactNode, useState } from 'react';
import Modal from 'react-modal';
import { accountEditFormValidationSchema } from '../../lib/Form/accountEditFormValidationSchema';
import { accountEditFieldsArray, AccountEditFormInitialValues, FormGroupFieldMap } from '../../lib/Form/fieldsArray';
import { UserInfo } from '../../models/user-model';
import AuthService from '../../services/auth-service';
import DBService from '../../services/db-service';
import ModalService from '../../services/modal-service';
import Button from '../Button/Button';
import Card from '../Card/Card';
import FormField from '../Form/FormField/FormField';
import './ProfileModal.scss';

// Apply once for accessibility needs
const root = document.getElementById('root');

if (root) {
  Modal.setAppElement(root);
}

enum EditProfileScreen {
  Profile,
  ConfirmCancel,
}

const ProfileModal = (): JSX.Element => {
  const profileType = AuthService.getModalType();
  const profile = AuthService.getProfile(profileType);
  const [screen, setScreen] = useState<number>(EditProfileScreen.Profile);
  const [editMode, setEditMode] = useState<boolean>(false);

  const handleSubmit = async (values: FormikValues, actions: FormikHelpers<typeof AccountEditFormInitialValues>) => {
    actions.setSubmitting(false);
    const newUser = values as UserInfo;

    await DBService.updateUserInfo(newUser, profileType);
    setEditMode(false);
    ModalService.closeModal();
  }

  const formGroupFieldMap: FormGroupFieldMap = {};

  accountEditFieldsArray.forEach(f => {
    if (formGroupFieldMap[f.formGroup]) {
      formGroupFieldMap[f.formGroup].push(f);
    } else {
      formGroupFieldMap[f.formGroup] = [f];
    }
  })

  return (
    <Modal
      isOpen
      className='profile-modal'
      overlayClassName='profile-modal-overlay'
      onRequestClose={() => editMode && setScreen(EditProfileScreen.ConfirmCancel)}
    >
      <div>
        {screen === EditProfileScreen.Profile && (
          <Card title={`Edit ${profileType === 'billingInfo' ? 'Billing' : 'Business'} Information`}>
            <Formik
              initialValues={{
                ...AccountEditFormInitialValues,
                ...profile,
              }}
              validationSchema={accountEditFormValidationSchema}
              onSubmit={handleSubmit}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {({ isSubmitting, errors, touched }) => (
                <Form onChange={() => setEditMode(true)}>
                  {
                    formGroupFieldMap && Object.keys(formGroupFieldMap).map((key, index): ReactNode =>
                      <div className='multi-step-form__group' key={`multi-step-form-group-${index}`}>
                        {
                          formGroupFieldMap[Number(key)].map((field, i): ReactNode =>
                            <FormField key={`multi-step-form-content-${i}`} errors={errors} touched={touched} {...field} />)
                        }
                      </div>,
                    )
                  }
                  <div className='button-group'>
                    <Button type='secondary' onClick={() => editMode ? setScreen(EditProfileScreen.ConfirmCancel) : ModalService.closeModal()}>
                      Cancel
                    </Button>
                    <Button type='submit' disabled={isSubmitting}>
                      Save
                    </Button>
                  </div>
                </Form>)}
            </Formik>
          </Card>
        )}
        {screen === EditProfileScreen.ConfirmCancel && (
          <Card title='Cancel Editing'>
            Are you sure you would like to close without saving your changes?
            <div className='button-group'>
              <Button type='secondary' onClick={() => setScreen(EditProfileScreen.Profile)}>
                Back
              </Button>
              <Button type='primary' onClick={() => ModalService.closeModal()}>
                Cancel Editing
              </Button>
            </div>
          </Card>
        )}
      </div>
    </Modal >
  );
};

export default ProfileModal;
