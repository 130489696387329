import { FunctionComponent } from 'react';
import cx from 'classnames';
import { X, Check, Info, AlertTriangle } from 'react-feather';
import './Banner.scss';

type BannerProps = {
  text: string;
  type: 'error' | 'success' | 'info' | 'alert'
};

const Banner: FunctionComponent<BannerProps> = ({
  text,
  type,
}): JSX.Element => {
  return (
    <div
      className={cx('banner', {
        'banner--alert': type === 'alert',
        'banner--error': type === 'error',
        'banner--success': type === 'success',
        'banner--info': type === 'info',
      })}
    >
      {type === 'success' && <Check size={15} />}
      {type === 'info' && <Info size={15} />}
      {type === 'alert' && <AlertTriangle size={15} />}
      {type === 'error' && <X size={15} />}
      <span className={'banner__text'}>{text}</span>
    </div>

  );
};

export default Banner;
