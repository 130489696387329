import { BatchModel } from '../models/batch-model';
import DBService from './db-service';
import AuthService from './auth-service';
import { UserModel } from '../models/user-model';
import firebase from 'firebase';

class BatchServiceClass {
  private static instance: BatchServiceClass;

  private lastVisibleBatch: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData> | undefined | null;

  public static getInstance(): BatchServiceClass {
    if (!BatchServiceClass.instance) {
      BatchServiceClass.instance = new BatchServiceClass();
    }
    return BatchServiceClass.instance;
  }

  async createBatch(selectedIds: Array<string>) {
    try {
      const batch: BatchModel = {shipmentIds: selectedIds};
      const oldUser = AuthService.getUser();

      await DBService.createBatch(batch);

      if (oldUser) {
        const newUser: UserModel = {
          ...oldUser,
          totalPendingShipments: (oldUser?.totalPendingShipments || 0) - selectedIds.length,
          totalBatches: (oldUser?.totalBatches || 0) + 1,
        };

        AuthService.setUser(newUser);
      }

    } catch (error) {
      console.error(error);
      throw new Error('Batch creation failed. ' + error);
    }
  }

  async getAllBatches(page: number, pageSize: number) {
    try {
      return await DBService.getAllBatches(page, pageSize);
    } catch (error) {
      return [];
    }
  }

  async searchBatches(searchText: string) {
    try {
      return await DBService.searchBatches(searchText);
    } catch (error) {
      return [];
    }
  }


  setLastVisibleBatch(lastVisible: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData> | undefined | null) {
    this.lastVisibleBatch = lastVisible;
  }

  getLastVisibleBatch() {
    return this.lastVisibleBatch;
  }
}

const BatchService = BatchServiceClass.getInstance();

export default BatchService;
