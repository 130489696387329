import { firestore } from '../firebase';
import { BatchModel } from '../models/batch-model';
import { CountModel } from '../models/count-model';
import { ShipmentModel } from '../models/shipment-model';
import { UserInfo, UserModel } from '../models/user-model';
import AuthService from './auth-service';
import BatchService from './batch-service';
import ShipmentService from './shipment-service';
import { BATCH, generateCount, generateId, SHIPMENT } from './helper';

/*
 * Data base Singleton that allows for accessing the data base.
 * Avoid using directly in react components. Instead abstract calls away to services.
 */
class DBServiceClass {
  private static instance: DBServiceClass;

  public static getInstance(): DBServiceClass {
    if (!DBServiceClass.instance) {
      DBServiceClass.instance = new DBServiceClass();
    }
    return DBServiceClass.instance;
  }

  /* --------------
   * Count DB Calls
   * ----------- */
  async getCount(type: string): Promise<CountModel> {
    const countDoc = firestore.collection('counts').where('type', '==', type).limit(1).get();

    try {
      const doc = (await countDoc).docs[0];
      const currentDoc = doc.data() as CountModel;

      return {
        id: currentDoc?.id,
        count: currentDoc?.count || 0,
        updatedAt: currentDoc?.updatedAt,
        type: currentDoc?.type,
      };
    } catch (error) {
      console.error(error);
      throw new Error('Unable to get current count');
    }
  }

  async updateCount(count: CountModel) {

    const countDoc = firestore.collection('counts').doc(count.id);

    try {
      await countDoc.update({
        count: count?.count || 0,
        updatedAt: count?.updatedAt || Date.now(),
      });

    } catch (error) {
      console.error(error);
      throw new Error('Unable to update current count');
    }
  }

  /* --------------
   * User DB Calls
   * ----------- */
  async createUser(user: UserModel) {
    const userId = user.id;
    const userDoc = firestore.collection('users').doc(userId);

    try {
      return await userDoc.set({ ...user });
    } catch (error) {
      console.error(error);
      throw new Error('Unable to get create user.');
    }
  }

  async getCurrentUser(): Promise<UserModel> {
    const userId = AuthService.getUserId();
    const userDoc = firestore.collection('users').doc(userId);
    const shipmentsCollection = firestore.collection('shipments').doc(`${userId}`).collection('shipments');
    const shipmentDoc = shipmentsCollection;
    const pendingShipmentDoc = shipmentsCollection.where('status', '==', 'READY');
    const cancelledShipmentDoc = shipmentsCollection.where('status', '==', 'CANCELLED');
    const batchDoc = firestore.collection('batches').doc(`${userId}`).collection('batches');
    const completedShipmentDoc = shipmentsCollection.where('status', '==', 'DELIVERED');
    const inProgressShipmentDoc = shipmentsCollection.where('status', '==', 'INPROGRESS');

    try {
      const doc = await userDoc.get();
      const currentUser = doc.data() as UserModel;
      const shipmentsDoc = await shipmentDoc.get();
      const pendingShipmentsDoc = await pendingShipmentDoc.get();
      const batchesDoc = await batchDoc.get();
      const cancelledShipmentsDoc = await cancelledShipmentDoc.get();
      const completedShipmentsDoc = await completedShipmentDoc.get();
      const inProgressShipmentsDoc = await inProgressShipmentDoc.get();

      return {
        id: userId,
        email: currentUser?.email || '',
        confirmedEmail: currentUser.confirmedEmail,
        createdAt: currentUser?.createdAt,
        balance: currentUser?.balance,
        billingInfo: {
          firstName: currentUser.billingInfo?.firstName || '',
          lastName: currentUser.billingInfo?.lastName || '',
          addressLine1: currentUser.billingInfo?.addressLine1 || '',
          addressLine2: currentUser.billingInfo?.addressLine2 || '',
          city: currentUser.billingInfo?.city || '',
          state: currentUser.billingInfo?.state || '',
          country: currentUser.billingInfo?.country || '',
          zip: currentUser.billingInfo?.zip || '',
          phoneNumber: currentUser.billingInfo?.phoneNumber || '',
        },
        businessInfo: {
          firstName: currentUser.businessInfo?.firstName || '',
          lastName: currentUser.businessInfo?.lastName || '',
          addressLine1: currentUser.businessInfo?.addressLine1 || '',
          addressLine2: currentUser.businessInfo?.addressLine2 || '',
          city: currentUser.businessInfo?.city || '',
          state: currentUser.businessInfo?.state || '',
          country: currentUser.businessInfo?.country || '',
          zip: currentUser.businessInfo?.zip || '',
          phoneNumber: currentUser.businessInfo?.phoneNumber || '',
        },
        totalBatches: batchesDoc.size || 0,
        totalPendingShipments: pendingShipmentsDoc.size || 0,
        totalShipments: shipmentsDoc.size || 0,
        totalCompletedShipments: completedShipmentsDoc.size || 0,
        totalCancelledShipments: cancelledShipmentsDoc.size || 0,
        totalInProgressShipments: inProgressShipmentsDoc.size || 0,
      };
    } catch (error) {
      console.error(error);
      throw new Error('Unable to get current user');
    }
  }
  async updateUserInfo(info: UserInfo, type: string) {
    const userId = AuthService.getUserId();
    const userDoc = firestore.collection('users').doc(userId);
    const { override, ...userInfo } = info;

    try {
      let updateInfo;

      if (override) {
        updateInfo = {
          billingInfo: userInfo,
          businessInfo: userInfo,
        };
      } else {
        updateInfo = {
          [type]: userInfo,
        };
      }

      await userDoc.update({
        ...updateInfo,
      });

      //update user
      const newUser: UserModel = {
        ...AuthService.getUser() as UserModel,
        ...updateInfo,
      };

      AuthService.setUser(newUser);
    } catch (error) {
      console.error(error);
      throw new Error('Unable to update current user');
    }
  }

  async updateUserBalance(adjustment: number) {
    const userId = AuthService.getUserId();
    const userDoc = firestore.collection('users').doc(userId);

    try {
      const oldUser = await this.getCurrentUser();
      const userBalance = oldUser?.balance || 0;

      await userDoc.update({ balance: userBalance + adjustment });

      const newUser: UserModel = {
        ...oldUser,
        balance: userBalance + adjustment,
      };

      AuthService.setUser(newUser);
    } catch (error) {
      console.error(error);
      throw new Error('Unable to get update user balance.');
    }
  }

  /* ------------------
   * Batch DB Calls
   * --------------- */
  async getAllBatches(page: number, pageSize: number): Promise<BatchModel[]> {
    const userId = AuthService.getUserId();
    let batchDoc;

    if (page > 0 && BatchService.getLastVisibleBatch()) {
      batchDoc = firestore
        .collection('batches').doc(`${userId}`).collection('batches')
        .orderBy('createdAt', 'desc')
        .startAfter(BatchService.getLastVisibleBatch())
        .limit(pageSize);
    } else {
      batchDoc = firestore
        .collection('batches').doc(`${userId}`).collection('batches')
        .orderBy('createdAt', 'desc')
        .limit(pageSize);
    }

    try {
      if (!batchDoc) {
        throw ('Batch Query Cannot be Empty');
      }
      const querySnapshot = await batchDoc.get();
      const batches = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) as unknown as BatchModel[];
      const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];

      BatchService.setLastVisibleBatch(lastVisible);
      return batches;
    } catch (error) {
      console.error(error);
      throw new Error('Unable to get all batches');
    }
  }

  async searchBatches(searchText: string): Promise<BatchModel[]> {
    const userId = AuthService.getUserId();

    const batchDoc = firestore
      .collection('batches')
      .doc(userId)
      .collection('batches')
      .where('batchId', '==', searchText);

    try {
      if (!batchDoc) {
        throw ('Batch Query Cannot be Empty');
      }
      const batchesQuerySnapshot = await batchDoc.get();
      const batches = batchesQuerySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) as unknown as BatchModel[];

      return batches;
    } catch (error) {
      console.error(error);
      throw new Error('Unable to get all batches');
    }
  }
  async createBatch(batch: BatchModel) {
    const oldUser = AuthService.getUser();
    const userId = AuthService.getUserId();
    const batchDoc = firestore.collection('batches').doc(`${userId}`).collection('batches').doc();
    let created = false;

    try {
      if (!oldUser) {
        throw new Error('User is undefined');
      }
      if (!batch.shipmentIds || batch?.shipmentIds.length === 0) {
        throw new Error('Batch must contain shipments');
      }

      const currentCount = await this.getCount(BATCH);
      const currentDate = Date.now();
      const currentDateObj = new Date(currentDate);
      const count = generateCount(currentCount, currentDateObj);
      const batchId = generateId(oldUser, count, BATCH, currentDateObj.toISOString())

      batch.id = batchDoc.id;
      batch.batchId = batchId;
      batch.createdAt = currentDate;
      batch.status = 'NEW';

      // Create new batch
      await batchDoc.set({ ...batch, userId, batchId });
      created = true;

      //update count
      await this.updateCount({ ...currentCount, count, updatedAt: currentDate } as CountModel);

      //update status of shipments
      await this.updateShipmentStatus(batch?.shipmentIds, batchId, 'BATCHED');
    } catch (error) {
      console.error(error);

      if (created) {
        await batchDoc.delete();
      }

      throw new Error(error?.message || 'Unable to create batch.');
    }
  }


  /* ------------------
   * Shipment DB Calls
   * --------------- */
  async createShipment(shipment: ShipmentModel) {
    const oldUser = AuthService.getUser();
    const userId = AuthService.getUserId();
    const shipmentDoc = firestore.collection('shipments').doc(`${userId}`).collection('shipments').doc();

    try {
      if (!oldUser) {
        throw new Error('User is undefined');
      }

      const currentCount = await this.getCount(SHIPMENT);
      const currentDate = Date.now();
      const currentDateObj = new Date(currentDate);
      const count = generateCount(currentCount, currentDateObj);
      const shipmentId = generateId(oldUser, count, SHIPMENT, currentDateObj.toISOString())

      shipment.id = shipmentDoc.id;
      shipment.shipmentId = shipmentId;

      // Create new shipment
      await shipmentDoc.set({ ...shipment, userId, shipmentId });

      // Update count
      await DBService.updateCount({ ...currentCount, count, updatedAt: currentDate } as CountModel);
    } catch (error) {
      console.error(error);
      throw new Error('Unable to get create/update the shipment');
    }
  }

  async updateShipmentStatus(shipmentIds: Array<string>, batchId: string, status: string) {
    try {
      const userId = AuthService.getUserId();

      // There is a hard limit of 3000. If the shipment is beyond the latest 3000 it is considered stale and needs to get remade.
      const shipmentsDoc = await (firestore.collection('shipments').doc(`${userId}`).collection('shipments').orderBy('createdAt', 'desc').limit(3000)).get();
      const shipmentIdSet = new Set(shipmentIds);
      const batch = firestore.batch();

      shipmentsDoc.docs.forEach(doc => {
        const docRef = firestore.collection('shipments').doc(`${userId}`).collection('shipments').doc(doc.id);
        const targetId = status === 'BATCHED' ? doc.data()?.shipmentId : doc.data()?.shipEngine?.labelId;

        if (shipmentIdSet.has(targetId)) {
          batch.update(docRef, { id: doc.id, ...doc.data(), status, batchId });
          shipmentIdSet.delete(targetId)
        }
      });

      if (shipmentIdSet.size > 0) {
        throw new Error('Stale shipments are not allowed, please remove them from the batch. ' + Array.from(shipmentIdSet).join(' '));
      }

      await batch.commit();
    } catch (error) {
      console.error(error);
      throw new Error(error?.message || 'Unable to update shipments status.');
    }
  }

  async updateShipmentLabelViewed(shipmentId: string) {
    const userId = AuthService.getUserId();

    try {
      const shipmentDoc = firestore.collection('shipments').doc(`${userId}`).collection('shipments').doc(shipmentId);

      await shipmentDoc.update({ labelViewed: true });
    } catch (error) {
      console.error(error);
      throw new Error('Unable to get update shipment label to viewed');
    }
  }

  async getLatestShipments(): Promise<ShipmentModel[]> {
    const userId = AuthService.getUserId();

    const shipmentDoc = firestore
      .collection('shipments').doc(`${userId}`).collection('shipments')
      .orderBy('createdAt', 'desc')
      .limit(20);

    try {
      if (!shipmentDoc) {
        throw ('Shipment Query Cannot be Empty');
      }
      const querySnapshot = await shipmentDoc.get();
      const shipments = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) as unknown as ShipmentModel[];

      return shipments;
    } catch (error) {
      console.error(error);
      throw new Error('Unable to get all shipments');
    }
  }

  async getAllCancelledShipments(page: number, pageSize: number): Promise<ShipmentModel[]> {
    const userId = AuthService.getUserId();
    let shipmentDoc;

    if (page > 0 && ShipmentService.getLastVisibleShipment()) {
      shipmentDoc = firestore
        .collection('shipments')
        .doc(`${userId}`)
        .collection('shipments')
        .where('status', '==', 'CANCELLED')
        .orderBy('createdAt', 'desc')
        .startAfter(ShipmentService.getLastVisibleShipment())
        .limit(pageSize);
    } else {
      shipmentDoc = firestore
        .collection('shipments').doc(`${userId}`).collection('shipments')
        .where('status', '==', 'CANCELLED')
        .orderBy('createdAt', 'desc')
        .limit(pageSize);
    }

    try {
      if (!shipmentDoc) {
        throw ('Shipment Query Cannot be Empty');
      }
      const querySnapshot = await shipmentDoc.get();
      const shipments = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) as unknown as ShipmentModel[];
      const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];

      ShipmentService.setLastVisibleShipment(lastVisible);
      return shipments;
    } catch (error) {
      console.error(error);
      throw new Error('Unable to get all shipments');
    }
  }

  async searchAllCancelledShipments(searchText: string): Promise<ShipmentModel[]> {
    const userId = AuthService.getUserId();

    const shipmentDoc = firestore
      .collection('shipments').doc(`${userId}`).collection('shipments')
      .where('status', '==', 'CANCELLED')
      .where('id', '==', searchText)
      .orderBy('createdAt', 'desc');

    try {
      if (!shipmentDoc) {
        throw ('Shipment Query Cannot be Empty');
      }
      const querySnapshot = await shipmentDoc.get();
      const shipments = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) as unknown as ShipmentModel[];

      return shipments;
    } catch (error) {
      console.error(error);
      throw new Error('Unable to search cancelled shipments');
    }
  }


  async getAllPendingShipments(page: number, pageSize: number): Promise<ShipmentModel[]> {
    const userId = AuthService.getUserId();
    let shipmentDoc;

    if (page > 0 && ShipmentService.getLastVisibleShipment()) {
      shipmentDoc = firestore
        .collection('shipments')
        .doc(`${userId}`)
        .collection('shipments')
        .where('status', '==', 'READY')
        .orderBy('createdAt', 'desc')
        .startAfter(ShipmentService.getLastVisibleShipment())
        .limit(pageSize);
    } else {
      shipmentDoc = firestore
        .collection('shipments').doc(`${userId}`).collection('shipments')
        .where('status', '==', 'READY')
        .orderBy('createdAt', 'desc')
        .limit(pageSize);
    }

    try {
      if (!shipmentDoc) {
        throw ('Shipment Query Cannot be Empty');
      }
      const querySnapshot = await shipmentDoc.get();
      const shipments = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) as unknown as ShipmentModel[];
      const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];

      ShipmentService.setLastVisibleShipment(lastVisible);
      return shipments;
    } catch (error) {
      console.error(error);
      throw new Error('Unable to get all shipments');
    }
  }

  async searchAllPendingShipments(searchText: string): Promise<ShipmentModel[]> {
    const userId = AuthService.getUserId();

    const shipmentDoc = firestore
      .collection('shipments').doc(`${userId}`).collection('shipments')
      .where('status', '==', 'READY')
      .where('id', '==', searchText)
      .orderBy('createdAt', 'desc');

    try {
      if (!shipmentDoc) {
        throw ('Shipment Query Cannot be Empty');
      }
      const querySnapshot = await shipmentDoc.get();
      const shipments = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) as unknown as ShipmentModel[];

      return shipments;
    } catch (error) {
      console.error(error);
      throw new Error('Unable to search shipments');
    }
  }

  async searchLatestShipments(searchText: string): Promise<ShipmentModel[]> {
    const userId = AuthService.getUserId();

    const shipmentDoc = firestore
      .collection('shipments').doc(`${userId}`).collection('shipments')
      .where('id', '==', searchText)
      .orderBy('createdAt', 'desc')
      .limit(20);

    try {
      if (!shipmentDoc) {
        throw ('Shipment Query Cannot be Empty');
      }
      const querySnapshot = await shipmentDoc.get();
      const shipments = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) as unknown as ShipmentModel[];

      return shipments;
    } catch (error) {
      console.error(error);
      throw new Error('Unable to search latest shipments');
    }
  }

  async getAllShipmentsWithBatches(): Promise<ShipmentModel[]> {
    const userId = AuthService.getUserId();
    const shipmentDoc = firestore.collection('shipments').doc(`${userId}`).collection('shipments')
      .where('batchId', '!=', '');

    try {
      const querySnapshot = await shipmentDoc.get();
      const shipments = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) as unknown as ShipmentModel[];

      return shipments;
    } catch (error) {
      console.error(error);
      throw new Error('Unable to get all shipments with batches');
    }
  }

  async getShipment(shipmentId: string): Promise<ShipmentModel> {
    const userId = AuthService.getUserId();
    const shipmentDoc = firestore.collection('shipments').doc(`${userId}`).collection('shipments').doc(shipmentId);

    try {
      const doc = await shipmentDoc.get();
      const shipment = doc.data() as ShipmentModel;

      return shipment;
    } catch (error) {
      console.error(error);
      throw new Error('Unable to get current user');
    }
  }
}

const DBService = DBServiceClass.getInstance();

export default DBService;
