import { Column } from 'material-table';
import Pill from '../../components/Pill/Pill';
import Button from '../../components/Button/Button';
import { statusBtnTypeMap } from './statusTypeMap';
import { ChevronRight } from 'react-feather';
import { ReactNode } from 'react';
import { ShipmentModel } from '../../models/shipment-model';
import { getTrackingLink } from '../../services/helper';
import { LabelDetails } from '../../components/MultiStepForm/MultiStepForm';
import CarrierService from '../../services/carrier-service';

type ShipmentColumnType = 'latest' | 'pending' | 'cancelled'

export type RowData = ShipmentModel & {
  orderNumber?: string;
  estimatedDeliveryDate?: string;
  label?: string;
  parentId?: string | undefined; //used for parentchild data
}

export type batchColumnType = RowData & {
  batchId: string;
  batchStatus: 'NEW' | 'SENT';
}

const renderTrackingNumber = (rowData: RowData): JSX.Element => {
  const labelDetails = rowData as unknown as LabelDetails;

  labelDetails.carrierId = rowData?.shipEngine?.carrierId || '';

  const carriersTrackingLinks = CarrierService.getCarriersTrackingLinks();

  return (
    <>
      {
        !labelDetails.trackingNumber ? ''
          : getTrackingLink(labelDetails, carriersTrackingLinks) ?
            <a href={getTrackingLink(labelDetails, carriersTrackingLinks)}
              target='_blank'
            >
              {labelDetails.trackingNumber}
            </a>
            : labelDetails.trackingNumber
      }
    </>
  )
}

export const shipmentsColumnGenerator = (columnType?: ShipmentColumnType): Column<RowData>[] => [
  {
    title: 'Customer',
    field: 'customer',
  },
  {
    title: 'Pickup Date',
    field: 'estimatedDeliveryDate',
    dateSetting: { locale: 'en-CA' },
    render: (rowData: RowData) => rowData?.estimatedDeliveryDate ? new Date(rowData.estimatedDeliveryDate).toDateString() : '',
    cellStyle: (rowData: RowData) => {
      const pickupDateString = rowData as unknown as Date; // Bug in material grid

      const pickupDate = new Date(pickupDateString);
      const now = new Date();

      return columnType === 'pending' && (pickupDate < now) && { color: '#FF6F5C' };
    },
  },
  {
    title: 'Destination',
    field: 'destination',
  },
  {
    title: 'Shipment Id',
    field: 'shipmentId',
  },
  {
    title: 'Order Number',
    field: 'orderNumber',
  },
  {
    title: 'Status',
    field: 'status',
    render: (rowData: RowData): ReactNode => <Pill label={rowData?.label || ''} type={statusBtnTypeMap[rowData?.status || 0]} />,
    cellStyle: {
      whiteSpace: 'nowrap',
    },
  },
  {
    title: 'Tracking Number',
    field: 'trackingNumber',
    render: (rowData: RowData): ReactNode => renderTrackingNumber(rowData),
  },
  {
    title: 'Created On',
    field: 'createdAt',
    dateSetting: { locale: 'en-CA' },
    defaultSort: columnType === 'latest' && 'desc',
    render: (rowData: RowData) => new Date(rowData.createdAt).toDateString(),
  },
  {
    title: 'View', field: 'view', sorting: false,
    render: (rowData: RowData): ReactNode => <Button type='secondary' to={`/shipment/view/${rowData?.orderNumber}`} smaller>
      <span>View</span>
      <ChevronRight size={20} style={{ marginRight: '-10px' }} />
    </Button>,
  },
] as Column<RowData>[];

export const batchColumnDefinition = [
  {
    title: 'Batch Id',
    field: 'batchId',
  },
  {
    title: 'Pickup Date',
    field: 'estimatedDeliveryDate',
    dateSetting: { locale: 'en-CA' },
    defaultSort: 'asc',
    render: (rowData: RowData) => rowData?.estimatedDeliveryDate ? new Date(rowData.estimatedDeliveryDate).toDateString() : '',
  },
  {
    title: 'Customer',
    field: 'customer',
  },
  {
    title: 'Destination',
    field: 'destination',
  },
  {
    title: 'Shipment Id',
    field: 'shipmentId',
  },
  {
    title: 'Order Number',
    field: 'orderNumber',
  },
  {
    title: 'Status',
    field: 'status',
    render: (rowData: RowData): ReactNode => <Pill label={rowData?.label || ''} type={statusBtnTypeMap[rowData?.status || 0]} />,
    cellStyle: {
      whiteSpace: 'nowrap',
    },
  },
  {
    title: 'Tracking Number',
    field: 'trackingNumber',
    render: (rowData: RowData): ReactNode => renderTrackingNumber(rowData),
  },
  {
    title: 'Created On',
    field: 'createdAt',
    dateSetting: { locale: 'en-CA' },
    render: (rowData: RowData) => rowData.parentId ? new Date(rowData.createdAt).toDateString() : '',
  },
  {
    title: 'View', field: 'view', sorting: false,
    render: (rowData: RowData): ReactNode => rowData.parentId && <Button type='secondary' to={`/shipment/view/${rowData?.orderNumber}`} smaller>
      <span>View</span>
      <ChevronRight size={20} style={{ marginRight: '-10px' }} />
    </Button>,
  },
] as Column<RowData>[];
