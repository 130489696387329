import { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from '../../components/Button/Button';
import Card from '../../components/Card/Card';
import CardRow from '../../components/CardRow/CardRow';
import { LabelDetails } from '../../components/MultiStepForm/MultiStepForm';
import Pill from '../../components/Pill/Pill';
import { statusBtnTypeMap } from '../../lib/table/statusTypeMap';
import { ShipmentModel } from '../../models/shipment-model';
import CarrierService from '../../services/carrier-service';
import { getTrackingLink } from '../../services/helper';
import ShipmentService from '../../services/shipment-service';
import { getDecimal, statusToLabel } from '../../services/helper';

interface IReactRouterParam {
  id?: string;
}

const ViewShipment: FunctionComponent = (): JSX.Element => {
  const { id } = useParams<IReactRouterParam>();
  const carriersTrackingLinks = CarrierService.getCarriersTrackingLinks();
  const [data, setData] = useState<ShipmentModel>();

  useEffect(() => {
    const getData = async () => {
      const shipmentData = await ShipmentService.getShipment(id);

      if (shipmentData) {
        setData(shipmentData as ShipmentModel);
      }
    };

    getData();
  }, []);

  const handleCancelLabel = () => {
    if (data) {
      ShipmentService.openCancelLabelModal(data);
    }
  }

  const getLink = () => {
    const shipmentDetail = {
      trackingNumber: data?.trackingNumber,
      carrierId: data?.shipEngine.carrierId,
    } as LabelDetails;

    return getTrackingLink(shipmentDetail, carriersTrackingLinks);
  }

  const pillType = statusBtnTypeMap[data?.status || 'READY'];
  const pillLabel = statusToLabel(data?.status);

  return (
    <CardRow cards={[
      <Card title='Shipment Details'>
        <div className='mt-2'><strong>Shipment ID</strong></div>
        <div>{id || 'N/A'}</div>
        <div className='mt-2 mb-1'><strong>Status</strong></div>
        <div><Pill label={pillLabel} type={pillType} /></div>
        <div className='mt-2'><strong>Tracking Number</strong></div>
        <div>
          {
            !data?.trackingNumber || data?.trackingNumber === 'N/A' ? 'N/A'
              : getLink() ?
                <a href={getLink()} target='_blank'>{data?.trackingNumber}</a>
                : data?.trackingNumber
          }
        </div>
        <div className='mt-2'><strong>Label Cost</strong></div>
        <div>{data?.cost ? '$' + getDecimal(data.cost) : 'N/A'}</div>
        {
          (id && data?.labelUrl) && <>
            <div className='mt-2'><strong>Reprint Label</strong></div>
            <div className='mt-2'>
              <Button type='primary' onClick={() => ShipmentService.openModal(id, data?.labelUrl || '')}>Click to View</Button>
            </div>
          </>
        }
        <div className='mt-2'><strong>Created On</strong></div>
        <div>{data?.createdAt ? (new Date(data.createdAt)).toDateString() : 'N/A'}</div>
        {
          data?.status !== 'CANCELLED' &&
          <div className='mt-2'>
            <Button type='cancel' onClick={() => data && handleCancelLabel()}>Cancel Shipping Label</Button>
          </div>
        }
      </Card>,
      <Card title='Recipient Details'>
        <div className='mt-2'><strong>Name</strong></div>
        <div>{data?.shipTo.name || 'N/A'}</div>
        <div className='mt-3'><strong>Phone</strong></div>
        <div>{data?.shipTo.phoneNumber || 'N/A'}</div>
        <div className='mt-2'><strong>Address</strong></div>
        {
          data?.shipTo.address &&
          <div>
            {`${data?.shipTo.address || ''}
          ${data?.shipTo.address2 && (', ' + data?.shipTo.address2)}, 
          ${data?.shipTo.city}, 
          ${data?.shipTo.state}, 
          ${data?.shipTo.country}, 
          ${data?.shipTo.postal}`
            }
          </div>
        }
        <div className='mt-2'><strong>Commodity Description</strong></div>
        <div>{data?.details.descriptionOfContents || 'N/A'}</div>
        <div className='mt-2'><strong>Retail Value</strong></div>
        <div>{`${data?.details.retailValue} ${data?.details?.currency}`}</div>
        <div className='mt-2'><strong>Weight</strong></div>
        <div>{`${data?.details.weight} ${data?.details.weightUnit}${(data?.details.weight || 0) > 1 ? 's' : ''}`}</div>
      </Card>,
    ]} />
  );
};

export default ViewShipment;
