import Cookies from 'js-cookie';

export type PersonalizationSettings = {
  forms: FormSettings;
  theme: ThemeSettings;
}

export type FormSettings = {
  recipientName?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string,
  country: string;
  state?: string;
  zip?: string;
  phoneNumber?: string;
  typeOfContents?: string;
  descriptionOfContents?: string;
  retailValue?: string;
  currency: 'USD' | 'CAD';
  packageType?: 'thickEnvelope' | 'parcel';
  weight?: string;
  weightUnit?: string;
  length?: string;
  width?: string;
  height?: string;
  sizeUnit?: string;
  shipFrom?: string;
  shipDate: string;
  rememberInfo?: boolean;
  rateId?: string;
}

export type ThemeSettings = {
  mode: 'light' | 'dark';
}

export const defaultPersonalizationSettings: PersonalizationSettings = {
  forms: {
    currency: 'USD',
    country: 'US',
    shipDate: new Date(new Date().setDate(new Date().getDate() + 1)).toISOString(),
  },
  theme: {
    mode: 'light',
  },
}

const COOKIE_NAME = 'personalizationSettings';

class PersonalizationServiceClass {
  private static instance: PersonalizationServiceClass;

  private _personalizationSettings: PersonalizationSettings = defaultPersonalizationSettings;

  public static getInstance(): PersonalizationServiceClass {
    if (!PersonalizationServiceClass.instance) {
      PersonalizationServiceClass.instance = new PersonalizationServiceClass();
      this.instance._personalizationSettings = this.getCookie();
    }
    return PersonalizationServiceClass.instance;
  }

  static getCookie() {
    const cookie = Cookies.get(COOKIE_NAME);

    return cookie ? JSON.parse(cookie) : defaultPersonalizationSettings;
  }

  public getPersonalizationSettings(): PersonalizationSettings {
    return this._personalizationSettings;
  }

  public setPersonalizationSettings(settings: PersonalizationSettings): void {
    this._personalizationSettings = settings;
    Cookies.set(COOKIE_NAME, JSON.stringify(settings));
  }

  public getPersonalizationFormSettings(): FormSettings {
    return this._personalizationSettings.forms;
  }

  public setPersonalizationFormSettings(formSettings: FormSettings): void {
    const settings = {
      forms: formSettings,
      theme: this.getPersonalizationThemeSettings(),
    }

    this.setPersonalizationSettings(settings);
  }

  public getPersonalizationThemeSettings(): ThemeSettings {
    return this._personalizationSettings.theme;
  }
}
const PersonalizationService = PersonalizationServiceClass.getInstance();

export default PersonalizationService;
