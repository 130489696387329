import { useEffect, useState } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Form, Formik } from 'formik';
import { paymentFormValidationSchema } from '../../lib/Form/paymentFormValidationSchema';
import { useHistory } from 'react-router-dom';
import { getDecimal } from '../../services/helper';
import AuthService from '../../services/auth-service';
import PaymentService from '../../services/payment-service';
import Banner from '../Banner/Banner';
import Button from '../Button/Button';
import Card from '../Card/Card';
import Loader from '../Loader/Loader';
import Modal from 'react-modal';
import TextField from '../Form/TextField/TextField';
import ModalService from '../../services/modal-service';
import successConfetti from '../../assets/success-confetti.json';
import lottie from 'lottie-web';
import './PaymentModal.scss';

// Apply once for accessibility needs
const root = document.getElementById('root');

if (root) {
  Modal.setAppElement(root);
}

enum PaymentScreen {
  Overview,
  ConfirmCancel,
  Payment,
  Confirmation,
  IncompleteProfile
}

const PaymentModal = (): JSX.Element => {
  const currentUser = AuthService.getUser();
  const billingProfile = AuthService.getUserBillingInfo();
  const infoText = PaymentService.getInfoText();
  const handlePostPayment = PaymentService.getHandlePostPayment();
  const history = useHistory();
  const [paymentAmount, setPaymentAmount] = useState<number>();
  const [screen, setScreen] = useState<number>(PaymentScreen.Overview);
  const [preCancelScreen, setPreCancelScreen] = useState<number>(PaymentScreen.Overview);
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [submittingStripe, setSubmittingStripe] = useState<boolean>(false);

  useEffect(() => {
    if (!AuthService.isBillingProfileComplete()) {
      setScreen(PaymentScreen.IncompleteProfile);
    }
  }, []);

  // Stripe
  const stripe = useStripe();
  const elements = useElements();

  const handlePayment = async (event?: Event) => {
    event?.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const cardComplete = document.getElementsByClassName('StripeElement--complete').length === 1;

    if (!cardComplete) {
      setSubmittingStripe(false);
      setError('Invalid card details, please double check your card information.');
      return;
    }

    if (cardElement && paymentAmount && cardComplete) {
      setSubmittingStripe(true);
      setError('');

      // Updating the tax (13%) will also need to be reflected in the backend payment-controller.ts and frontend payment-service.ts
      const totalAmount = paymentAmount * 1.13;
      const payment = await PaymentService.createPayment(stripe, cardElement, totalAmount);

      if (!payment?.error) {
        setSubmittingStripe(false);

        if (infoText && handlePostPayment) {
          handlePostPayment();
        } else {
          setSuccess(true);

          const element = document.querySelector('#payment-modal__animation-icon');

          if (element) {
            lottie.destroy();
            lottie.loadAnimation({
              container: element,
              animationData: successConfetti,
              loop: false,
            });
          }
        }
      } else {
        setSubmittingStripe(false);

        if (infoText && handlePayment && handlePostPayment) {
          handlePostPayment('Failed to add credits to your account. Please add funds to complete this transaction.');
        } else {
          setError('Could not process payment, please double check your card information.');
        }
      }
    }
  }

  const taxes = paymentAmount ? getDecimal(paymentAmount * 0.13) : 0;
  const subTotal = getDecimal(paymentAmount || 0);
  const total = getDecimal((paymentAmount || 0) * 1.13);

  return (
    <Modal
      isOpen
      className='payment-modal'
      overlayClassName='payment-modal-overlay'
      onRequestClose={() => {
        if (screen === PaymentScreen.Overview || screen === PaymentScreen.IncompleteProfile || success) {
          PaymentService.closeModal();
        } else if (!submittingStripe) {
          setPreCancelScreen(screen);
          setScreen(PaymentScreen.ConfirmCancel);
        }
      }}
    >
      <div>
        {screen === PaymentScreen.Overview && (
          <Card title='Add Credits'>
            <Formik
              initialValues={{ credits: 50 }}
              validationSchema={paymentFormValidationSchema}
              onSubmit={values => {
                setScreen(PaymentScreen.Payment)
                setPaymentAmount(values.credits);
              }}
            >
              {({ isSubmitting, errors, touched }) => (
                <Form>
                  <Banner type='info' text={infoText ? infoText : `Current Balance: ${getDecimal(currentUser?.balance || 0)}`} />
                  <div><strong>Payment Amount</strong></div>
                  <TextField
                    name='credits'
                    label='Credits (CAD)'
                    placeholder='Enter amount'
                    type='number'
                    errors={errors}
                    touched={touched}
                  />
                  <div><strong>Billing Details</strong></div>
                  <div>{AuthService.getBillingProfileFullName()}</div>
                  <div>
                    {`${billingProfile?.addressLine1}`}
                  </div>
                  {billingProfile?.addressLine2 && <div>{billingProfile?.addressLine2}</div>}
                  <div>
                    {`${billingProfile?.city}, ${billingProfile?.state}, ${billingProfile?.country}, ${billingProfile?.zip}`}
                  </div>
                  <div>{billingProfile?.phoneNumber}</div>
                  <div className='button-group'>
                    <Button type='secondary' onClick={() => ModalService.closeModal()}>
                      Cancel
                    </Button>
                    <Button type='submit' disabled={isSubmitting}>
                      Next
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Card>
        )}
        {screen === PaymentScreen.Payment && (
          <Card title={!success ? 'Complete Payment' : 'Payment Success'}>
            {error && <Banner type='error' text={error} />}
            {success && (
              <Banner type='success' text={`New Balance: $${getDecimal(currentUser?.balance || 0)}`} />
            )}
            <div className='payment-modal__animation--wrapper'>
              <div id='payment-modal__animation-icon' />
            </div>
            {success && (
              <div className='payment-modal__success-text'><strong>Your balance has been updated!</strong></div>
            )}
            {!success && <>
              <div className='mt-2'><strong>Email Address</strong></div>
              <div>{currentUser?.email || 'N/A'}</div>
              <div className='mt-2'><strong>Card Details</strong></div>
              <div className='mt-1'>
                <CardElement id='stripeCardElement' />
              </div>
              <div className='mt-2'><strong>Payment Summary</strong></div>
              <div className='cost-row'>Subtotal <span>${subTotal}</span></div>
              <div className='cost-row'>Taxes (13%) <span>${taxes}</span></div>
              <div className='underline'/>
              <div className='cost-row'>Total: <span>${total}</span></div>
            </>
            }

            <div className='button-group'>
              {
                success
                  ?
                  <Button type='primary' onClick={() => PaymentService.closeModal()}>
                    Close
                  </Button>
                  :
                  <>
                    <Button type='secondary' disabled={submittingStripe} onClick={() => setScreen(PaymentScreen.Overview)}>
                      Back
                    </Button>
                    <Button type='primary' disabled={submittingStripe} onClick={handlePayment}>
                      {submittingStripe ? <Loader /> : 'Complete Payment'}
                    </Button>
                  </>
              }
            </div>
          </Card>
        )}
        {screen === PaymentScreen.ConfirmCancel && (
          <Card title='Cancel Transaction'>
            Are you sure you would like to cancel this transaction?
            <div className='button-group'>
              <Button type='secondary' onClick={() => setScreen(preCancelScreen)}>
                Back
              </Button>
              <Button type='primary' onClick={() => PaymentService.closeModal()}>
                Cancel Transaction
              </Button>
            </div>
          </Card>
        )}
        {screen === PaymentScreen.IncompleteProfile && (
          <Card title='Incomplete Profile'>
            <Banner type='error' text='Please fill in billing details before proceeding.' />
            <div className='button-group'>
              <Button type='primary' onClick={() => {
                PaymentService.closeModal();
                history.push('/settings');
              }}>
                Go to Settings
              </Button>
            </div>
          </Card>
        )}
      </div>
    </Modal>
  );
};

export default PaymentModal;
