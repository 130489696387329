import { FunctionComponent, useState } from 'react';
import TabTitle from './TabTitle';
import './Tabs.scss';

type TabsProps = {
  tabsLabel: string[];
  tabsContent: JSX.Element[];
};

const Tabs: FunctionComponent<TabsProps> = ({
  tabsLabel,
  tabsContent,
}): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div className='tabs'>
      <ul className='tabs__labels'>
        {
          tabsLabel.map((label, index) => (
            <TabTitle key={`tab-title-${index}`} selectedTab={selectedTab} setSelectedTab={setSelectedTab} index={index} title={label} />
          ))
        }
      </ul>
      <div className='tabs__content'>
        {tabsContent[selectedTab]}
      </div>
    </div>
  );
};

export default Tabs;
