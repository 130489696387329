/* eslint-disable camelcase */
import ApiService from './api-service';

type Carrier = {
  carrier_id: string,
  carrier_code: string,
}

class CarrierServiceClass {
  private static instance: CarrierServiceClass;

  private _carriers?: Carrier[];

  private _carriersTrackingLinks: { [key: string]: string } = {};

  public static getInstance(): CarrierServiceClass {
    if (!CarrierServiceClass.instance) {
      CarrierServiceClass.instance = new CarrierServiceClass();
    }
    return CarrierServiceClass.instance;
  }

  public async initCarriers() {
    try {
      const result = await ApiService.post('getAllCarriers', {});

      if (result?.data?.carriers) {
        this._carriers = result?.data?.carriers.map((c: Carrier) => c.carrier_id);
        result.data.carriers.forEach((c: Carrier) => {
          if (c.carrier_code.toLowerCase().indexOf('fedex') > -1) {
            this._carriersTrackingLinks[c.carrier_id] = 'https://www.fedex.com/fedextrack/?trknbr=';
          } else if (c.carrier_code.toLowerCase().indexOf('ups') > -1) {
            this._carriersTrackingLinks[c.carrier_id] = 'http://wwwapps.ups.com/WebTracking/track?trackNums=';
          } else if (c.carrier_code.toLowerCase().indexOf('stamps_com') > -1) {
            this._carriersTrackingLinks[c.carrier_id] = 'https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=';
          }

        })
      }
    } catch (err) {
      this._carriers = [];
    }
  }

  public async getCarriers(): Promise<Carrier[]> {
    if (!this._carriers) {
      await this.initCarriers();
    }

    return this._carriers || [];
  }
  public getCarriersTrackingLinks() {
    return this._carriersTrackingLinks;
  }
}
const CarrierService = CarrierServiceClass.getInstance();

export default CarrierService;
