import Card from '../Card/Card';
import debounce from 'lodash.debounce';
import { shipmentsColumnGenerator, RowData } from '../../lib/table/summaryCardCols';
import { useHistory } from 'react-router';
import { useEffect, useState, useCallback } from 'react';
import ShipmentService from '../../services/shipment-service';
import SummaryCard from './SummaryCard';
import { default as PaginationTable } from '../MaterialTable/PaginationTable';
import AuthService from '../../services/auth-service';
import { PAGE_SIZE, DEBOUNCE_MS } from '../MaterialTable/tableHelper';
import Loader from '../Loader/Loader';
import Banner from '../Banner/Banner';
import { statusToLabel } from '../../services/helper';
import { ChevronRight } from 'react-feather';

type ShipmentCardProps = {
  latest?: boolean;
  summaryCard?: boolean;
}

const ShipmentCard = ({ latest, summaryCard }: ShipmentCardProps): JSX.Element => {
  const history = useHistory();
  const [data, setData] = useState<RowData[]>();
  const [error, setError] = useState('');
  const [debouncedSearchText, setDebouncedSearchText] = useState('');
  const [pagination, setPagination] = useState<{ page: number, pageSize: number }>({ page: 0, pageSize: PAGE_SIZE });
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getRowData = async () => {
    setIsLoading(true);
    let rowData;

    if (latest) {
      if (debouncedSearchText !== '') {
        rowData = await ShipmentService.searchLatestShipments(debouncedSearchText);
      } else {
        rowData = await ShipmentService.getLatestShipments();
      }
    } else if (debouncedSearchText !== '') {
      rowData = await ShipmentService.searchAllPendingShipments(debouncedSearchText);
    } else {
      rowData = await ShipmentService.getAllPendingShipments(pagination.page, pagination.pageSize);
    }
    if (rowData) {
      const newData = rowData.map<RowData>((row: RowData) => {
        return {
          ...row,
          orderNumber: row.id || 'N/A',
          shipmentId: row.shipmentId || 'N/A',
          trackingNumber: row.trackingNumber || 'N/A',
          customer: row.shipTo.name,
          destination: row.shipTo.city + ', ' + row.shipTo.state,
          label: statusToLabel(row.status),
        }
      });

      setData(newData);
      setIsLoading(false);
    } else {
      setData([]);
    }
  };

  useEffect(() => {
    getRowData();
  }, [pagination, debouncedSearchText]);

  useEffect(() => {
    return () => {
      ShipmentService.setLastVisibleShipment(null);
    }
  }, []);

  const fetchDataByPage = (page: number, pageSize: number) => {
    setPagination({ page, pageSize });
  }

  const onSearchChange = (searchText: string) => {
    searchDebounce(searchText);
  }

  const searchDebounce = useCallback(
    debounce((_searchText: string) => {
      setDebouncedSearchText(_searchText);
    }, DEBOUNCE_MS),
    [],
  );

  const pageSize = debouncedSearchText !== '' ? 10 : pagination.pageSize;
  const page = debouncedSearchText !== '' ? 0 : pagination.page;

  const getTotalCount = () => {
    if (debouncedSearchText) {
      return data?.length || 0;
    }

    if (latest) {
      if ((AuthService.getUser()?.totalShipments || 0) >= PAGE_SIZE) {
        return PAGE_SIZE;
      }
      return AuthService.getUser()?.totalShipments || 0;
    }
    return AuthService.getUser()?.totalPendingShipments || 0;
  };

  const conditionalTableProps = latest ? {
    actionBtnText: (
      <>
        View All Shipments
        <ChevronRight size={20} />
      </>
    ),
    actionFunc: () => history.push('shipment'),
    shouldDisableButtonFunc: () => false,
  } : {
    actionBtnText: 'Create Batch',
    actionFunc: (values: any) => {
      try {
        setError('')
        ShipmentService.openCreateSetModal(values.selectedRows);
      } catch (error) {
        setError(error?.message || 'Unable to create batch');
      }
    },
  };

  return (
    <>
      {summaryCard && <SummaryCard />}
      <Card
        title={latest ? `Latest Shipments` : `Pending Shipments`}
        actionLabel='Create a Shipment'
        actionFunc={() => history.push('shipment/create')}
        actionIcon
      >
        {!latest && !error && !!data && data?.length > 0 && (
          <Banner
            type='alert'
            text='You have unbatched shipments. Ensure to batch and generate documents before their pickup date. Failure to do so will require generating a new label.'
          />
        )}
        {
          error && <Banner
            text={error}
            type='error'
          />
        }
        {!data ? <Loader /> : (
          <PaginationTable
            rowData={data}
            isLoading={isLoading}
            totalCount={getTotalCount()}
            columnDefinition={shipmentsColumnGenerator(latest ? 'latest' : 'pending')}
            page={page}
            pageSize={Math.min(pageSize, data.length + 1)}
            fetchDataByPage={fetchDataByPage}
            pagination={!latest && pageSize === PAGE_SIZE}
            onSearchChange={onSearchChange}
            disableSelection={latest}
            {...conditionalTableProps}
          />
        )}
      </Card>
    </>
  );
};

export default ShipmentCard;
