import { FunctionComponent } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

type PrivateRouteProps = {
  loggedIn: boolean;
} & RouteProps;

const PrivateRoute: FunctionComponent<PrivateRouteProps> = ({
  loggedIn,
  children,
  ...routeProps
}): JSX.Element => {
  if (loggedIn) {
    return <Route {...routeProps}>{children}</Route>;
  }

  return <Redirect to={'/login'} />;
};

export default PrivateRoute;
