import { FunctionComponent } from 'react';
import cx from 'classnames';
import './Pill.scss';

export type PillProps = {
  label: string | number;
  subLabel?: string | number;
  type: 'primary' | 'secondary' | 'tertiary' | 'alert' | 'success' | 'error';
};

const Pill: FunctionComponent<PillProps> = ({
  label,
  subLabel,
  type,
}): JSX.Element => {
  return (
    <div className={cx(
      'pill',
      {
        'pill--primary': type === 'primary',
        'pill--secondary': type === 'secondary',
        'pill--tertiary': type === 'tertiary',
        'pill--alert': type === 'alert',
        'pill--success': type === 'success',
        'pill--error': type === 'error',
      },
    )}>
      <div>
        <span>
          {label}
        </span>
        {subLabel && <p>{subLabel}</p>}
      </div>
    </div>
  );
};

export default Pill;
