/* eslint-disable camelcase */
export enum MODAL_STATE {
    INACTIVE,
    PROFILE_MODAL,
    PAYMENT_MODAL,
    LABEL_PRINT_MODAL,
    CREATE_SET_MODAL,
    CANCEL_LABEL_MODAL,
    PROCEED_WITHOUT_VALIDATION,
}

class ModalServiceClass {
  private static instance: ModalServiceClass;

  private _subscribers: Array<(modalIsOpen: boolean) => void> = [];

  private isModalOpen = false;

  private modalState = MODAL_STATE.INACTIVE;

  public static getInstance(): ModalServiceClass {
    if (!ModalServiceClass.instance) {
      ModalServiceClass.instance = new ModalServiceClass();
    }
    return ModalServiceClass.instance;
  }

  getModalState() {
    return this.modalState;
  }

  openModal(modalState: MODAL_STATE) {
    this.isModalOpen = true;
    this.modalState = modalState;
    this.notifyAll();
  }

  closeModal() {
    this.isModalOpen = false;
    this.modalState = MODAL_STATE.INACTIVE;
    this.notifyAll();
  }

  subscribe(callback: (modalIsOpen: boolean) => void): void {
    this._subscribers.push(callback);
  }

  unsubscribe(callback: (modalIsOpen: boolean) => void): void {
    this._subscribers = this._subscribers.filter(cb => cb !== callback);
  }

  notifyAll(): void {
    for (let i = 0; i < this._subscribers.length; i++) {
      this._subscribers[i](this.isModalOpen);
    }
  }
}
const ModalService = ModalServiceClass.getInstance();

export default ModalService;
