import { FunctionComponent, useState, useEffect } from 'react';
import AuthPage from './AuthPage';
import AuthService from '../../services/auth-service';
import Card from '../../components/Card/Card';
import { Link, useLocation, RouteProps } from 'react-router-dom';
import { Form, Formik, FormikHelpers, FormikValues } from 'formik';
import Button from '../../components/Button/Button';
import Checkbox from '../../components/Form/Checkbox/Checkbox';
import TextField from '../../components/Form/TextField/TextField';
import { loginFormValidationSchema } from '../../lib/Form/loginFormValidationSchema';
import Banner from '../../components/Banner/Banner';
const initialValues = {
  email: '',
  password: '',
  rememberMe: '',
};

interface LoginLocationState {
  passwordReset: boolean;
}

const Login: FunctionComponent<RouteProps> = (): JSX.Element => {
  const routerLocation = useLocation();
  const [location] = useState(routerLocation);
  const [formError, setFormError] = useState('');
  const [passwordResetSuccessful, setPasswordResetSuccessful] = useState(false);

  useEffect(()=>{
    if (!passwordResetSuccessful && location) {
      const state = location.state as LoginLocationState;

      setPasswordResetSuccessful(state?.passwordReset);
    }
  }, [location, passwordResetSuccessful])

  const handleSubmit = async (values: FormikValues, actions: FormikHelpers<typeof initialValues>) => {
    actions.setSubmitting(false);

    // TODO - use correct prop type instead of any
    const result: any = await AuthService.emailLogin(values.email, values.password, values.rememberMe);

    if (result) {
      setFormError(result?.message || 'Something went wrong, please try again later.');
    }
  }

  const renderFormError = formError && <Banner type='error' text={formError}/>
  const renderPasswordResetMessage = passwordResetSuccessful && <Banner type='success' text='Your password has successfully been updated'/>

  return (
    <AuthPage>
      <Card title='Login'>
        { renderFormError }
        { renderPasswordResetMessage }
        <Formik
          initialValues={initialValues}
          validationSchema={loginFormValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, errors, touched }) => (
            <Form>
              <TextField
                name='email'
                label='Email'
                placeholder='Enter email'
                type='email'
                errors={errors}
                touched={touched}
              />
              <TextField
                name='password'
                label='Password'
                placeholder='Enter password'
                type='password'
                errors={errors}
                touched={touched}
              />
              <Checkbox
                name='rememberMe'
                label='Remember Me'
              />
              <Button type='submit' disabled={isSubmitting}>
                Sign In
              </Button>
            </Form>
          )}
        </Formik>
        <div className='mt-2'>
          Forgot your password?{' '}
          <Link to='/forgot-password' className='text-link'>
            Reset Password
          </Link>
        </div>
      </Card>
    </AuthPage>
  );
};

export default Login;
